import { memo } from "react";
import Countdown from "react-countdown";
import { MdTimer } from "react-icons/md";
import { Fragment } from "react/jsx-runtime";

type Props = {
  minutes: number;
  onFinish?: () => void;
  className?: string;
};
const renderer = ({ minutes, seconds }) => (
  <Fragment>
    {String(minutes).length === 1 ? `0${minutes}` : minutes}:
    {String(seconds).length === 1 ? `0${seconds}` : seconds}
  </Fragment>
);
export const Timer = memo((props: Props) => {
  return (
    <div
      className={`flex justify-end pb-2 items-center gap-2 px-1 ${props.className}`}
    >
      <MdTimer className="text-xl text-green-500" />
      <span className="text-green-500 w-[45px]">
        <Countdown
          onComplete={props.onFinish}
          renderer={renderer}
          date={Date.now() + props.minutes * 60 * 1000}
        />
      </span>
    </div>
  );
});
