import { CorrectWords } from "5-entities/searchCorrectWords/ui";
import { WriterDatasetItem } from "@entities";
export const WriterPage = () => {
  return (
    <div className="flex">
      <CorrectWords />
      <div className="flex flex-1 p-6 items-center  flex-col h-[calc(100vh-64px)]">
        <WriterDatasetItem />
      </div>
    </div>
  );
};
