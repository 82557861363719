import { TestHeader, TestList, useTestStore } from "@entities";
import { CustomPagination } from "@shared";

export const TestPage = () => {
  const { count } = useTestStore();
  return (
    <div className="w-full flex flex-col">
      <TestHeader />
      <TestList />
      <CustomPagination total={count} />
    </div>
  );
};
