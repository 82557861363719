import { IRate, IUser, orderValues } from "@shared";

export enum datasetStatus {
  ready = "ready",
  processing = "processing",
  new = "new",
  conflict = "conflict",
  reviewing = "reviewing",
  rejected = "rejected",
  drafted = "drafted",
}

export enum transcriptStatus {
  pending = "pending",
  ready = "ready",
  rejected = "rejected",
  conflict = "conflict",
}

export type transcriptStatusType =
  | transcriptStatus.pending
  | transcriptStatus.ready
  | transcriptStatus.rejected
  | transcriptStatus.conflict;

export type datasetStatusValueType = keyof typeof datasetStatus;

export enum sortTypeKeys {
  id = "id",
  rate = "rate",
}

export type sortKeysType = keyof typeof sortTypeKeys;

export interface ITranscript {
  id: number;
  body: string;
  datasetId: number;
  createdAt: string;
  status: transcriptStatusType;
  userId: number;
  rateAmount: number;
  user: IUser;
  likes?: IRate[];
  dislikes?: IRate[];
  dataset?: IDataset;
}

export interface IDataset {
  id: number;
  audio: string;
  path: string;
  map?: number;
  amountOfLikes?: number;
  amountOfDislikes?: number;
  text: string;
  status: datasetStatusValueType;
  createdAt: Date;
  updatedAt: Date;
  freezedTime: number;
  transcripts?: ITranscript[];
}

export interface IUndo {
  [key: number]: {
    timeout: NodeJS.Timeout;
    endTime: number;
    rejected: boolean;
    text: string;
  };
}

export interface IDatasetStore {
  datasets: IDataset[];
  setDatasets: (datasets: IDataset[]) => void;
  addDataset: (dataset: IDataset) => void;
  updateDataset: (dataset: IDataset) => void;
  updateTranscript: (id: number, body: string) => void;
  deleteDataset: (id: number) => void;

  amountOfDatasetItems: number;
  setAmountOfDatasetItems: (amountOfDataset: number) => void;

  sortOrder: keyof typeof orderValues;
  setSortOrder: (sortOrder: keyof typeof orderValues) => void;

  selectedDatasetId: number | null;
  setSelectedDatasetId: (selectedDatasetId: number | null) => void;

  clearDataset: boolean;
  setClearDataset: (clearDataset: boolean) => void;

  datasetModalId: number | null;
  setDatasetModalId: (datasetModalId: number | null) => void;

  undo: IUndo;
  setUndo: (undo: IUndo) => void;

  loading: boolean;
  setLoading: (loading: boolean) => void;
}
