import { Button } from "@nextui-org/react";
import { useSettingsStore } from "../model";
import { SettingsItem } from "./settingsItem";
import { useSettingsHook } from "../hooks";
import { useEffect } from "react";

export const SettingsPage = () => {
  const { settings } = useSettingsStore();
  const { updateSetting, getSettings } = useSettingsHook();
  const handleSubmit = () => {
    if (settings.find((setting) => setting.error)) return;
    updateSetting();
  };
  useEffect(() => {
    getSettings();
  }, []);
  return (
    <div className="p-8 w-[500px]">
      <h1>Settings</h1>
      <div className="flex flex-col gap-3 pt-4">
        {settings
          .sort((a, b) => a.id - b.id)
          .map((setting) => (
            <SettingsItem
              handleSubmit={handleSubmit}
              settings={setting}
              key={setting.id}
            />
          ))}
      </div>
      <div className="flex justify-end">
        <Button size="sm" className="mt-4" onClick={handleSubmit}>
          Save settings
        </Button>
      </div>
    </div>
  );
};
