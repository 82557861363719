import { Button } from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";

export const InstructionModalButton = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(pathname + search + "#instructions")}
      className=""
      color="success"
      size="sm"
    >
      Instruction
    </Button>
  );
};
