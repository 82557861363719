import { useDurationStore, useTestStore } from "@entities";
import { adminRoutes, adminSideBarRoutes } from "../model";
import { usePathnameHook } from "@shared";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export const SideBarRoutes = () => {
  const { getBasePathname, pathname } = usePathnameHook();

  const duration = useDurationStore();
  const { quantityTests } = useTestStore();

  const quantity = {
    ...(duration.quantity ? { ...duration.quantity } : {}),
    ...quantityTests,
  };

  const basePathname = getBasePathname();

  const sidebar = adminSideBarRoutes[basePathname];
  const route = adminRoutes.find((route) => route.path === basePathname);

  if (sidebar && route)
    return (
      <div className="w-[280px] bg-black border-r-2 border-r-zinc-700 pt-8 px-2">
        <h2 className="px-3 text-xl pb-4">{route.key}</h2>
        <div className="flex flex-col gap-1">
          {sidebar.map((route) => (
            <Link
              className={`px-3 py-1 flex justify-between rounded hover:bg-zinc-800 ${
                pathname.includes(route.path) ? "" : "text-gray-500"
              }`}
              key={route.path}
              to={route.to || route.path}
            >
              <span className="w-1/2">{route.label}</span>
              {route.quantity && quantity && (
                <Fragment>
                  <span className="flex-1 text-center">-</span>
                  <span className="flex-1 text-end">
                    {quantity[route.quantity] || 0}
                  </span>
                </Fragment>
              )}
            </Link>
          ))}
        </div>
      </div>
    );

  return null;
};
