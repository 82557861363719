import { LoginForm } from "@entities";
import { routes, useAppStore } from "@shared";
import { Navigate } from "react-router-dom";

export const Login = () => {
  const { user } = useAppStore();
  if (user) return <Navigate to={routes[user.role].key} />;

  return (
    <div className="flex w-full h-screen items-center justify-center">
      <LoginForm />
    </div>
  );
};
