import {
  Button,
  Code,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useDatasetStore } from "../model";
import { useState } from "react";
import { useDatasetHook } from "../hooks/hook";

export const DatasetClear = () => {
  const { clearDataset, setClearDataset } = useDatasetStore();
  const { truncateDataset } = useDatasetHook();
  const [text, setText] = useState("");

  const handleInput = (value: string) => {
    if (value.includes(" ")) return;
    setText(value.toUpperCase());
  };
  const handleSubmit = async () => {
    if (text !== "CLEARDATABASE") return;
    truncateDataset();
    onClose();
  };

  const handleKey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      handleSubmit();
    }
  };

  const onClose = () => {
    setText("");
    setClearDataset(false);
  };
  return (
    <Modal
      backdrop={"blur"}
      isOpen={clearDataset}
      onClose={onClose}
      className="dark"
      isDismissable={false}
      size="lg"
      placement="top-center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex  gap-1 text-white">
              <span>Write </span>{" "}
              <Code size="sm" color="danger" className="mx-2 ">
                CLEARDATABASE
              </Code>{" "}
              <span>to clear database</span>
            </ModalHeader>
            <ModalBody>
              <Input
                autoFocus
                onPaste={(e) => e.preventDefault()}
                color="danger"
                value={text}
                onValueChange={handleInput}
                onKeyDown={handleKey}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="flat" onPress={onClose}>
                Close
              </Button>
              <Button color="danger" onPress={handleSubmit}>
                Clear
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
