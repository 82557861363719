import { useInstructionsStore } from "../model";
import { InstructionItemModal } from "./instructionItemModal";

export const InstructionListModal = () => {
  const { instructions } = useInstructionsStore();
  return (
    <div className="border-t-2 mt-6 border-stone-600">
      {instructions
        .sort((a, b) => a.id - b.id)
        .map((instruction) => (
          <InstructionItemModal
            instruction={instruction}
            key={instruction.id}
          />
        ))}
    </div>
  );
};
