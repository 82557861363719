import { useAppStore } from "6-shared/store";
import "./index.css";
import { useEffect, useState } from "react";

type Props = {
  force?: boolean;
};

export const Loader = (props: Props) => {
  const { loading, full } = useAppStore();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (loading) setShow(true);
    else {
      setTimeout(() => {
        setShow(false);
      }, 200);
    }
  }, [loading]);

  if (show || props.force || (loading && full))
    return (
      <div
        className={`transition-all absolute w-full bg-black h-screen z-50 flex items-center justify-center ${
          loading ? "opacity-100" : "opacity-20"
        }`}
      >
        <div className={`loader ${props.force ? "scale-80" : ""}`}></div>
      </div>
    );
};
