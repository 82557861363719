import { useParams, useSearchParams } from "react-router-dom";
import { useWordsHook } from "../hook";
import { useWordsStore } from "../store";
import { orderValues, sortOrderKey, WordsLoader } from "@shared";
import { useEffect } from "react";
import { WordItem } from "./wordItem";
import { useSearchParamsHook } from "../hook/params.hook";

export const WordList = () => {
  const { words } = useWordsStore();
  const { getWords, loading } = useWordsHook();

  const { orderBy, orderDirection, page, searchQuery } = useSearchParamsHook();

  useEffect(() => {
    getWords(Number(page), orderDirection, orderBy, searchQuery);
  }, [page, orderDirection, orderBy, searchQuery]);

  return (
    <div className="h-[calc(100vh-175px)] relative overflow-y-auto">
      <WordsLoader loading={loading} />
      <div className="flex flex-wrap pt-2 px-4">
        {words.map((word) => (
          <WordItem word={word} key={word.id} />
        ))}
      </div>
    </div>
  );
};
