import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { formatPhoneNumber, IUser, role, roleType } from "@shared";
import { useEffect, useState } from "react";
import { useUsersHook } from "../hooks";
import { useUsersStore } from "../model";

type Props = {
  user: IUser;
};

export const UserItem = (props: Props) => {
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [roleVaue, setRoleValue] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(0);

  const { setSelectedUserId } = useUsersStore();

  useEffect(() => {
    setUsername(props.user.username);
    setRoleValue(props.user.role);
    setPhone(props.user.phone);
    setPassword("");
  }, [props.user]);
  const { editUser } = useUsersHook();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (username.trim() === "") return setError(1);
    if (password.trim() !== "" && password.trim().length < 6)
      return setError(2);

    setError(0);

    editUser(props.user.id, {
      role: roleVaue,
      username,
      phone,
      ...(Boolean(password.trim()) ? { password } : {}),
    });
  };
  return (
    <form onSubmit={handleSubmit} className="flex gap-4">
      <Input
        size="sm"
        onChange={(e) => setUsername(e.target.value)}
        placeholder="username"
        color={error === 1 ? "danger" : "default"}
        value={username}
      />
      <Input
        size="sm"
        // onChange={(e) => setPhone(e.target.value)}
        placeholder="phone"
        color={error === 1 ? "danger" : "default"}
        value={phone}
        onValueChange={(value) => formatPhoneNumber(value, setPhone)}
      />
      <Select
        size="sm"
        aria-label="Role"
        placeholder="Select role"
        className="w-full"
        aria-hidden="false"
        selectedKeys={[roleVaue]}
        onChange={(e) => setRoleValue(e.target.value as roleType)}
      >
        <SelectItem key={role.auditor}>{role.auditor}</SelectItem>
        <SelectItem key={role.writer}>{role.writer}</SelectItem>
      </Select>
      <Input
        onChange={(e) => setPassword(e.target.value)}
        onBlur={() => setError(0)}
        size="sm"
        type="password"
        placeholder="password"
        color={error === 2 ? "danger" : "default"}
        value={password}
      />
      <div className="flex gap-4 items-center">
        <Button
          isIconOnly
          variant="light"
          className="rounded-full"
          type="submit"
          size="sm"
        >
          <MdEdit className="text-xl" />
        </Button>
        <Button
          isIconOnly
          size="sm"
          variant="light"
          className="rounded-full"
          onClick={() => setSelectedUserId(props.user.id)}
        >
          <MdDelete color="red" className="text-xl" />
        </Button>
      </div>
    </form>
  );
};
