import { create } from "zustand";
import { IWordStore } from "../types";

export const useWordsStore = create<IWordStore>((set) => ({
  words: [],
  totalAmount: 0,
  setWords(words, totalAmount) {
    set({ words, totalAmount });
  },
  updateExistedWord(word) {
    set((state) => ({
      words: state.words.map((w) => (w.id === word.id ? { ...w, ...word } : w)),
    }));
  },

  selectedIds: [],
  setSelectedIds(selectedIds) {
    set({ selectedIds });
  },
  addToSelectedIds(id) {
    set((state) => ({
      selectedIds: [...state.selectedIds, id],
    }));
  },
  removeFromSelectedIds(id) {
    set((state) => ({
      selectedIds: state.selectedIds.filter((selectedId) => selectedId !== id),
    }));
  },

  wordsModal: null,
  setWordsModal(wordsModal) {
    set({ wordsModal });
  },
}));
