import { Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";

type props = { loading: boolean };

export const ComponentLoader = (props: props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.loading) setShow(true);
    else {
      setTimeout(() => {
        setShow(false);
      }, 200);
    }
  }, [props.loading]);

  if (props.loading || show)
    return (
      <div
        className={`transition-all fixed top-0 left-0 w-full h-full bg-black flex justify-center z-10 items-center  ${
          props.loading ? "opacity-60" : "opacity-20"
        }`}
      >
        <Spinner color="white" />
      </div>
    );
};
