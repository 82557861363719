import { Input } from "@nextui-org/react";
import { useState } from "react";
import { useUsersHook } from "../hooks";
import { Duration } from "@shared";
type props = {
  paid: number;
  accepted: number;
  userId: number;
};
export const PaidInput = (props: props) => {
  const [value, setValue] = useState("0");
  const { updatePaidValue } = useUsersHook();

  const error =
    isNaN(Number(value)) ||
    !Boolean(value.trim()) ||
    value.includes(" ") ||
    Number(value) > props.accepted / 3600 ||
    (value.startsWith("0") && Number(value) !== 0 && !value.includes(".")) ||
    value.endsWith(".") ||
    value.startsWith("-") ||
    value.startsWith("+");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      event.stopPropagation();
    }
    if (error) return;
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      updatePaidValue(props.userId, Number(value));
      setValue("0");
    }
  };
  return (
    <div className="flex items-center">
      <span className="flex-1">
        {" "}
        <Duration seconds={props.paid * 3600} format="hh:mm" />
      </span>
      <Input
        onKeyDown={handleKeyDown}
        value={String(value)}
        color={error ? "danger" : "default"}
        onValueChange={setValue}
        className="w-1/3"
        variant="flat"
        size="sm"
        onFocus={(e) => e.stopPropagation()}
      />
    </div>
  );
};
