import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { UserCreationFormInputs, userSchema } from "../types";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { formatPhoneNumber, Loader, role, useAppStore } from "@shared";
import { useUsersHook } from "../hooks";

export const CreateUser = () => {
  const { loading } = useAppStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<UserCreationFormInputs>({
    resolver: zodResolver(userSchema),
  });

  const { createUser, message } = useUsersHook();

  return (
    <div className="px-4 m-4 shadow-sm shadow-gray-800 pb-4">
      <h2 className="py-4">Create User</h2>
      <form
        onSubmit={handleSubmit(createUser(setValue))}
        className="flex flex-col gap-3"
      >
        <Input
          {...register("username")}
          size="sm"
          type="username"
          value={watch("username")}
          placeholder="username"
          color={errors.username ? "danger" : "default"}
        />
        <Input
          {...register("phone")}
          size="sm"
          type="username"
          value={watch("phone")}
          placeholder="phone"
          color={errors.phone ? "danger" : "default"}
          onValueChange={(value) =>
            formatPhoneNumber(value, (formatted: string) =>
              setValue("phone", formatted)
            )
          }
        />
        <Select
          {...register("role")}
          size="sm"
          aria-label="Role"
          color={errors.role ? "danger" : "default"}
          placeholder="Select role"
          className="w-full"
          aria-hidden="false"
        >
          <SelectItem key={role.auditor}>{role.auditor}</SelectItem>
          <SelectItem key={role.writer}>{role.writer}</SelectItem>
        </Select>
        <Input
          {...register("password")}
          size="sm"
          type="password"
          value={watch("password")}
          placeholder="password"
          color={errors.password ? "danger" : "default"}
        />
        {message && <p className={`text-xs text-red-600`}>{message}</p>}
        <Button size="sm" disabled={loading} className="relative" type="submit">
          {loading ? <Loader force /> : "Submit"}
        </Button>
      </form>
    </div>
  );
};
