type props = {
  error: string | null;
  className?: string;
};

export const ErrorText = (props: props) => {
  if (props.error)
    return (
      <span className={`text-danger-500 text-xs px-1 ${props.className}`}>
        {props.error}
      </span>
    );
};
