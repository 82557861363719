import { Checkbox, Input } from "@nextui-org/react";
import { useState } from "react";
import { useWordsStore } from "../store";
import { IWord } from "../types";
import { useWordsHook } from "../hook";

type props = { word: IWord };
export const WordItem = (props: props) => {
  const [value, setValue] = useState(props.word.body);
  const { selectedIds, addToSelectedIds, removeFromSelectedIds } =
    useWordsStore();
  const handleSelect = (value: boolean) => {
    value
      ? addToSelectedIds(props.word.id)
      : removeFromSelectedIds(props.word.id);
  };

  const { updateWord } = useWordsHook();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (event.key === "Enter" || event.key === "NumpadEnter") &&
      value !== props.word.body
    ) {
      event.preventDefault();
      updateWord(props.word.id, value);
    }
  };
  return (
    <div className="w-1/4 flex p-2">
      <Checkbox
        size="md"
        color="default"
        isSelected={Boolean(selectedIds.find((id) => id === props.word.id))}
        onValueChange={handleSelect}
      />
      <Input
        onKeyDown={handleKeyDown}
        value={value}
        size="sm"
        onValueChange={setValue}
      />
    </div>
  );
};
