import { IDataset } from "@entities";
import {
  checkForDataInLocalStorage,
  getInfoFromLocalStorage,
  keyForWriterData,
  localStorageKey,
  METHODS,
  useAxios,
} from "@shared";
import { useCallback, useEffect, useState } from "react";
import { ILocatStorageData, IStatistics } from "../types";
import { useWriterStore } from "../model";

export const useWriterHook = () => {
  const { fetchData } = useAxios();
  const [dataset, setDataset] = useState<null | IDataset>(null);
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setStatistics } = useWriterStore();

  useEffect(() => {
    getDatasetForWriter();
    refreshStatistics();
  }, []);

  // const revertDatasetStatus = useCallback(async () => {
  //   await fetchData(`/dataset/revert/${dataset?.id}`, METHODS.PUT, {
  //     map: dataset.map,
  //   });
  // }, [dataset]);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", revertDatasetStatus);
  //   return () => {
  //     window.removeEventListener("beforeunload", revertDatasetStatus);
  //   };
  // }, [revertDatasetStatus]);

  const getDatasetForWriter = useCallback(async () => {
    setLoading(true);

    const response = await fetchData<IDataset>(
      `/dataset/writer${getInfoFromLocalStorage()}`
    );
    acceptResponse(response);
  }, []);

  const setTranscription = useCallback(
    async (datasetId: number, text: string, map: number) => {
      setDataset(null);
      localStorage.removeItem(keyForWriterData);
      const response = await fetchData<IDataset>(
        "/dataset/writer",
        METHODS.POST,
        { body: text, datasetId, map }
      );
      refreshStatistics();
      acceptResponse(response);
    },
    []
  );

  const refreshStatistics = useCallback(async () => {
    const response = await fetchData<IStatistics>(
      "/dataset/writer/statistics",
      METHODS.GET,
      null,
      false
    );
    if (response.data) setStatistics(response.data);
  }, []);

  const acceptResponse = (response: { data: IDataset; message: string }) => {
    if (response && Boolean(response.data)) {
      localStorage.setItem(
        localStorageKey,
        `${response.data.id}-${response.data.map}`
      );

      if (+checkForDataInLocalStorage(keyForWriterData) !== response.data.id)
        setInLocalStorage(response.data.id, response.data.text, {});
      setDataset(response.data);
    } else setEmpty(true);
  };

  const getWriterDataFromLocalStorage = useCallback(
    (id: number, text: string): ILocatStorageData => {
      const defaultValue = {
        id,
        text,
        listened: false,
        played: false,
      };
      try {
        const data = localStorage.getItem(keyForWriterData);
        if (!data) return defaultValue;
        const writerData: ILocatStorageData = JSON.parse(data);
        if (writerData.id !== id) return defaultValue;
        return writerData;
      } catch (error) {
        return defaultValue;
      }
    },
    []
  );

  const setInLocalStorage = useCallback(
    (id: number, text: string, data: Partial<ILocatStorageData>) => {
      const storedData = getWriterDataFromLocalStorage(id, text);
      localStorage.setItem(
        keyForWriterData,
        JSON.stringify({ ...storedData, ...data, text })
      );
    },
    []
  );

  return {
    dataset,
    getDatasetForWriter,
    loading,
    setTranscription,
    empty,
    getWriterDataFromLocalStorage,
    setInLocalStorage,
  };
};
