import { Button, ButtonGroup } from "@nextui-org/button";
import { ThemeProvider } from "./providers/theme.provider";
import { AuthProvider } from "./providers/auth.provider";
import { Pages } from "@pages";

export const App = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Pages />
      </AuthProvider>
    </ThemeProvider>
  );
};
