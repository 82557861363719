export enum role {
  admin = "admin",
  auditor = "auditor",
  writer = "writer",
}

export const routes = {
  [role.admin]: {
    key: "/admin",
    users: {
      key: "users",
      path: "/admin/users",
      userList: {
        key: "list",
        path: "/admin/users/list",
      },
      scores: {
        key: "scores",
        path: "/admin/users/scores",
      },
      statistics: {
        key: "statistics",
        path: "/admin/users/statistics",
      },
    },
    dataset: {
      key: "dataset",
      path: "/admin/dataset",
      new: {
        key: "new/:page",
        path: "/admin/dataset/new/1",
        paginationPath: "/admin/dataset/new/",
      },
      rejected: {
        key: "rejected/:page",
        path: "/admin/dataset/rejected/1",
        paginationPath: "/admin/dataset/rejected/",
      },
      drafted: {
        key: "drafted/:page",
        path: "/admin/dataset/drafted/1",
        paginationPath: "/admin/dataset/drafted/",
      },
      reviewing: {
        key: "reviewing/:page",
        path: "/admin/dataset/reviewing/1",
        paginationPath: "/admin/dataset/reviewing/",
      },
      processing: {
        key: "processing/:page",
        path: "/admin/dataset/processing/1",
        paginationPath: "/admin/dataset/processing/",
      },
      conflict: {
        key: "conflict/:page",
        path: "/admin/dataset/conflict/1",
        paginationPath: "/admin/dataset/conflict/",
      },
      ready: {
        key: "ready/:page",
        path: "/admin/dataset/ready/1",
        paginationPath: "/admin/dataset/ready/",
      },
    },
    test: {
      key: "test",
      path: "/admin/test",
      writer: {
        key: "writer/:page",
        path: "/admin/test/writer/1",
        paginationPath: "/admin/test/writer/",
      },
      auditor: {
        key: "auditor/:page",
        path: "/admin/test/auditor/1",
        paginationPath: "/admin/test/auditor/",
      },
    },
    settings: {
      key: "settings",
      path: "/admin/settings",
    },
    instructions: {
      key: "instructions",
      path: "/admin/instructions",
    },
    words: {
      key: "words/:page",
      path: "/admin/words/1",
    },
  },

  [role.writer]: {
    key: "/writer",
    path: "/writer",
  },

  [role.auditor]: {
    key: "/auditor",
    path: "/auditor",
  },

  login: {
    key: "/login",
    path: "/login",
  },
} as const;

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum audioSpeeds {
  SLOW = 0.5,
  NORMAL = 1,
  FAST = 2,
}

export const sortOrderKey = "sortOrder";

export const sortTypeKey = "sortType";

export const searchKey = "search";

export const openKey = "open";

export const localStorageKey = "what.do.you.want.to.do.with.this.key";

export const keyForWriterData = "what.do.you.want.to.do.with.this.key.writer";
export const keyForAuditorData = "what.do.you.want.to.do.with.this.key.auditor";

export const waitTime = 10000;
