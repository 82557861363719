import { Button, Input } from "@nextui-org/react";
import { IInstruction, instructionDelete } from "../types";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInstructionsHook } from "../hooks";
import { Editor } from "@tinymce/tinymce-react";
type Props = {
  instruction?: IInstruction;
};
export const InstructionItem = (props: Props) => {
  const [title, setTitle] = useState("");
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { addInstructions, updateInstructions } = useInstructionsHook();
  useEffect(() => {
    if (props.instruction) setTitle(props.instruction.title);
  }, [props.instruction]);
  const handleDelete = () => {
    props.instruction &&
      navigate(pathname + instructionDelete + props.instruction.id);
  };

  const handleSave = () => {
    if (!editorRef.current) return;
    const description = editorRef.current.getContent();
    if (title.trim() === "" || description.trim() === "") return;
    if (props.instruction) {
      if (
        props.instruction.title.trim() === title.trim() &&
        props.instruction.description.trim() === description.trim()
      )
        return;
      updateInstructions({ ...props.instruction, title, description });
    } else {
      addInstructions({ title, description });
      editorRef.current.setContent("");
      setTitle("");
    }
  };
  return (
    <div className="flex">
      <div className="w-1/5 p-5 border-l-2 border-b-2 border-stone-600 flex flex-col gap-2">
        <Input placeholder="title" value={title} onValueChange={setTitle} />
        <div className="flex gap-2">
          {props.instruction ? (
            <Fragment>
              <Button onClick={handleSave} size="sm">
                Update
              </Button>
              <Button onClick={handleDelete} color="danger" size="sm">
                Delete
              </Button>
            </Fragment>
          ) : (
            <Button onClick={handleSave} size="sm">
              Save
            </Button>
          )}
        </div>
      </div>
      <div className="w-4/5 p-5 border-r-2 border-l-2 border-b-2 border-stone-600">
        <Editor
          apiKey={process.env.REACT_APP_EDITOR_KEY}
          onInit={(_evt, editor) => (editorRef.current = editor)}
          init={{
            skin: "oxide-dark",
            content_css: "dark",

            toolbar:
              "undo redo | fontfamily fontsize | bold italic underline strikethrough | forecolor",
          }}
          initialValue={props.instruction?.description || ""}
        />
      </div>
    </div>
  );
};
