import { role, roleType } from "@shared";
import { useUsersStore } from "../model";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { useSettingsStore } from "@entities";
import { RxReset } from "react-icons/rx";
type props = {
  role: roleType;
};

export const UserScoreTable = (props: props) => {
  const { users, setResetId, setUserModalId } = useUsersStore();
  const {
    getAuditorCheckingLimit,
    getWriterCheckingLimit,
    getAuditorTestLimit,
    getWriterTestLimit,
  } = useSettingsStore();
  const checkingLimit = {
    [role.auditor]: getAuditorCheckingLimit(),
    [role.writer]: getWriterCheckingLimit(),
  };
  const testLimit = {
    [role.auditor]: getAuditorTestLimit(),
    [role.writer]: getWriterTestLimit(),
  };

  return (
    <Table
      classNames={{
        base: "h-[100vh-152px] overflow-scroll",
      }}
      disableAnimation={true}
    >
      <TableHeader>
        <TableColumn className="text-center">USERNAME</TableColumn>
        <TableColumn className="text-center">PHONE</TableColumn>
        <TableColumn className="text-center">
          {props.role === role.auditor ? "RATES" : "TRANSCRIPTS"}
        </TableColumn>
        <TableColumn className="text-center">TEST</TableColumn>
        <TableColumn className="text-center">CHECKING</TableColumn>
        <TableColumn className="text-center">RESET</TableColumn>
        <TableColumn className="text-center">ACTIONS</TableColumn>
      </TableHeader>
      <TableBody emptyContent={"No rows to display."}>
        {users
          .sort((user1, user2) => user2.score.checking - user1.score.checking)
          .sort((user1, user2) => user2.score.test - user1.score.test)
          .filter((user) => user.role === props.role)
          .map((user) => {
            const red =
              user.score.test >= testLimit[props.role] ||
              user.score.checking >= checkingLimit[props.role];
            return (
              <TableRow key={user.id} className={`${false && "bg-red-500"}`}>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className="text-center cursor-pointer"
                >
                  {user.username}
                </TableCell>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className="text-center cursor-pointer text-xs"
                >
                  {user.phone}
                </TableCell>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className={`text-center`}
                >
                  {props.role === role.auditor
                    ? user.dislikes.length + user.likes.length
                    : user.transcripts.length}
                </TableCell>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className={`text-center ${
                    user.score.test >= testLimit[props.role] && "bg-red-700"
                  }`}
                >
                  {user.score.test}
                </TableCell>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className={`text-center ${
                    user.score.checking >= checkingLimit[props.role] &&
                    "bg-red-700"
                  }`}
                >
                  {user.score.checking}
                </TableCell>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className="text-center"
                >
                  {user.score.reset}
                </TableCell>
                <TableCell className="text-center  ">
                  {red && (
                    <div
                      onClick={() => setResetId(user.id)}
                      className="cursor-pointer w-full h-full flex justify-center"
                    >
                      <RxReset />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
