import { IAppStore, IUser } from "@shared";
import { create } from "zustand";

export const useAppStore = create<IAppStore>((set) => ({
  user: null,
  setUser: (user: IUser | null) => set({ user }),

  full: true,
  loading: true,
  setLoading: (loading: boolean, full: boolean = true) => {
    return set({ loading, full });
  },
  error: null,
  setError: (error: string | null) => set({ error }),
  info: null,
  setInfo: (info: string | null) => {
    set({ info });
  },

  toastId: null,
  setToastId: (toastId: string | null) => {
    set({ toastId });
  },

  uploadProgress: null,
  setUploadProgress(uploadProgress) {
    set({ uploadProgress });
  },
}));
