import { useSearchParams } from "react-router-dom";
import { ResultList } from "./resultList";
import { SearchInput } from "./searchInput";
import { openKey, searchKey } from "@shared";
import { Button } from "@nextui-org/react";
import { RiArrowRightWideLine, RiArrowLeftWideLine } from "react-icons/ri";
export const CorrectWords = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.get(openKey);
  const handleToggle = () => {
    isOpen ? searchParams.delete(openKey) : searchParams.set(openKey, "1");
    setSearchParams(searchParams);
  };
  return (
    <div
      style={{ width: isOpen ? "300px" : "0px" }}
      className="relative  transition-width h-[calc(100vh-64px)] pt-4"
    >
      <div
        style={{ transform: `translateX(${isOpen ? "0" : "-300px"})` }}
        className="transition-all"
      >
        <SearchInput />
        <ResultList />
      </div>

      <div
        onClick={handleToggle}
        className="absolute z-10 cursor-pointer top-20 -right-[24px]"
      >
        {isOpen ? (
          <RiArrowLeftWideLine className="text-3xl" />
        ) : (
          <RiArrowRightWideLine className="text-3xl" />
        )}
      </div>
    </div>
  );
};
