import { METHODS, useAppStore } from "@shared";
import axios, { AxiosRequestConfig } from "axios";

const development = process.env.NODE_ENV !== "production";

axios.defaults.baseURL =
  (development ? process.env.REACT_APP_URL : process.env.REACT_APP_PROD_URL) +
  "";

export const useAxios = () => {
  const { setLoading, setError } = useAppStore();
  const fetchData = async <T = any>(
    url: string,
    method: AxiosRequestConfig["method"] = METHODS.GET,
    body: object | null = null,
    defaultLoader: boolean = true,
    headers: AxiosRequestConfig["headers"] = {},
    withCredentials: boolean = true,
    onUploadProgressFunction?: (progressEvent: number) => void
  ): Promise<{ data: T; message: string }> => {
    try {
      setError(null);
      defaultLoader && setLoading(true, true);
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        withCredentials,
        onUploadProgress: (progressEvent) =>
          onUploadProgressFunction &&
          onUploadProgressFunction(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          ),
      };
      if (body) {
        config.data = body;
      }
      const res = await axios(config);
      return { data: res.data, message: res.statusText };
    } catch (err: any) {
      console.log(err.status);
      if (
        err.status === 401 &&
        url !== "/auth/check" &&
        url !== "/auth/login"
      ) {
        window.location.reload();
      }

      setLoading(false);
      if (err.response)
        return { message: err.response.data.message, data: null };
      return { message: "something went wrong", data: null };
    } finally {
      defaultLoader && setLoading(false, false);
    }
  };

  return { fetchData };
};
