import { UserScoreTable } from "@entities";
import { role } from "@shared";

export const UsersWithScores = () => {
  return (
    <div className="flex p-6 w-full gap-4">
      <div className="flex-1 flex flex-col">
        <h2 className="pb-4">Writers</h2>
        <UserScoreTable role={role.writer} />
      </div>
      <div className="flex-1 flex flex-col">
        <h2 className="pb-4">Auditors</h2>
        <UserScoreTable role={role.auditor} />
      </div>
    </div>
  );
};
