import { Input } from "@nextui-org/react";
import { ISettingsWithError } from "../types";
import { useSettingsStore } from "../model";
import { useUsersStore } from "@entities";

type Props = {
  settings: ISettingsWithError;
  handleSubmit: () => void;
};

export const SettingsItem = (props: Props) => {
  const { updateSetting } = useSettingsStore();
  const { getAuditors } = useUsersStore();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let error =
      isNaN(Number(value)) ||
      !Boolean(value.trim()) ||
      value.startsWith("0") ||
      value.startsWith("-") ||
      value.startsWith("+") ||
      value.includes(".") ||
      Number(value) > 10000;

    let condition = false;

    if (props.settings.key === "Conflict ratio") {
      condition = Number(value) < 0 || Number(value) > 100;
    }

    if (props.settings.key === "Rate quantity") {
      condition = Number(value) > getAuditors().length;
    }

    if (
      props.settings.key === "Random writer test range" ||
      props.settings.key === "Random auditor test range"
    ) {
      if (Number(value) === 0 && value.length === 1) error = false;
    }

    updateSetting(props.settings.key, value, error || condition);
  };

  const handleKey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      props.handleSubmit();
    }
  };

  return (
    <div className="flex items-center">
      <span className="flex-1">{props.settings.key}</span>
      <Input
        onInput={handleInput}
        onKeyDown={handleKey}
        size="sm"
        className="flex-1"
        color={props.settings.error ? "danger" : "default"}
        value={props.settings.value}
      />
    </div>
  );
};
