import { WriterPage } from "@features";
import { PageProps, routes, useAppStore } from "@shared";
import { Header } from "@widgets";
import { Navigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export const Writer = (props: PageProps) => {
  const { user } = useAppStore();

  if (!user || user.role !== props.role)
    return <Navigate to={routes.login.key} />;
  return (
    <Fragment>
      <Header />
      <WriterPage />
    </Fragment>
  );
};
