import { useUsersStore } from "../model";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { PaidInput } from "./paidInput";
import { IUserScore } from "../types";
import { Duration } from "@shared";
type props = {
  users: IUserScore[];
};

export const UserStatisticsTable = (props: props) => {
  const { setUserModalId } = useUsersStore();

  return (
    <Table
      color="default"
      classNames={{
        base: "h-[100vh-152px] overflow-scroll",
      }}
      disabledBehavior="all"
    >
      <TableHeader>
        <TableColumn className="text-center">USERNAME</TableColumn>
        <TableColumn className="text-center">PHONE</TableColumn>
        <TableColumn className="text-center">ACCEPTED</TableColumn>
        <TableColumn className="text-center">REJECTED</TableColumn>
        <TableColumn className="text-center">PAID</TableColumn>
      </TableHeader>
      <TableBody emptyContent={"No rows to display."}>
        {props.users
          .sort((a, b) => a.id - b.id)
          .map((user) => {
            return (
              <TableRow key={user.id}>
                <TableCell
                  onClick={() => setUserModalId(user.id)}
                  className="text-center cursor-pointer"
                >
                  {user.username}
                </TableCell>
                <TableCell className="text-center">{user.phone}</TableCell>
                <TableCell className={`text-center`}>
                  <Duration seconds={user.statistics.accepted} format="hh:mm" />
                </TableCell>

                <TableCell className="text-center">
                  <Duration seconds={user.statistics.rejected} format="hh:mm" />
                </TableCell>
                <TableCell className={`text-center`}>
                  <PaidInput
                    paid={user.statistics.paid}
                    accepted={user.statistics.accepted}
                    userId={user.id}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};
