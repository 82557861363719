import { Button, Card, Input, Spinner } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { LoginFormInputs, loginSchema } from "../types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthHook } from "../hooks";
import { Loader } from "@shared";
export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: zodResolver(loginSchema),
    mode: "all",
  });

  const { login, loading, message } = useAuthHook();

  return (
    <Card
      className="w-[380px] py-6 bg-transparent px-10 border-none"
      shadow="sm"
    >
      <h1 className="text-2xl py-4 text-center">Login</h1>
      <form onSubmit={handleSubmit(login)} className="flex flex-col gap-3">
        <Input
          {...register("username")}
          type="username"
          placeholder="username"
          color={errors.username ? "danger" : "default"}
        />
        <Input
          {...register("password")}
          type="password"
          placeholder="password"
          color={errors.password ? "danger" : "default"}
        />
        {message && <p className={`text-xs text-red-600`}>{message}</p>}
        <Button disabled={loading} className="relative" type="submit">
          {loading ? <Loader force /> : "Submit"}
        </Button>
      </form>
    </Card>
  );
};
