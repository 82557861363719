import { Button } from "@nextui-org/react";
import { IoIosRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
type props = {
  completed: boolean;
};
export const TimeCompleted = (props: props) => {
  const navigate = useNavigate();
  if (props.completed)
    return (
      <div className="absolute top-0 left-0 w-full z-50 h-screen bg-black opacity-70 flex justify-center items-center">
        <Button
          onClick={() => navigate(0)}
          variant="light"
          isIconOnly
          className="rounded-full"
        >
          <IoIosRefresh className="text-4xl" />
        </Button>
      </div>
    );
};
