import {
  AudioPlayer,
  CustomTextarea,
  DateFormat,
  ErrorText,
  useTextAreaHook,
} from "@shared";
import { datasetStatus, IDataset } from "../types";
import { DatasetHistoryItem } from "./datasetHistoryItem";
import { Button, Input, Tooltip } from "@nextui-org/react";
import { Fragment, useEffect, useState } from "react";
import { useDatasetHook } from "../hooks/hook";

type props = {
  dataset: IDataset;
};

export const DatasetHistory = (props: props) => {
  const [path, setPath] = useState(props.dataset.path);
  const { updatePath, editDataset } = useDatasetHook();

  const handleUpdatePath = (e) => {
    e.preventDefault();
    updatePath(props.dataset.id, path);
    handleUpdate();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      (event.key === "Enter" || event.key === "NumpadEnter") &&
      event.shiftKey
    ) {
      if (props.dataset.status === datasetStatus.new) handleUpdate();
    }
  };

  const handleUpdate = () => {
    if (determineErrors()) return;
    if (text === props.dataset.text) return;

    editDataset(props.dataset.id, shortenSpaces(text));
  };

  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook();

  useEffect(() => {
    if (Boolean(props.dataset.text)) setText(props.dataset.text);
  }, [props.dataset.text]);

  return (
    <div>
      <div className="">
        {Boolean(props.dataset.text) && (
          <Fragment>
            <ErrorText error={error} />
            <CustomTextarea
              onKeyDown={handleKeyDown}
              value={text}
              setValue={setText}
              error={error}
              disabled={props.dataset.status !== datasetStatus.new}
              className="!bg-black !h-[250px] !p-4 !text-lg"
            />
          </Fragment>
        )}
        <form className="pt-4 flex gap-2" onSubmit={handleUpdatePath}>
          <Tooltip
            key="danger"
            color="secondary"
            placement="top-start"
            content="The platform does not respond in the presence of the correct path"
            size="lg"
            classNames={{ content: "!bg-white !text-black" }}
          >
            <Input
              size="sm"
              classNames={{
                inputWrapper: ["!bg-black", "!text-xs"],
              }}
              value={path}
              onChange={(e) => setPath(e.target.value)}
            />
          </Tooltip>

          <Button type="submit" className="bg-black" size="sm">
            Update
          </Button>
        </form>

        <div className="flex items-center justify-between pt-2">
          <AudioPlayer
            disableKeyboardControls
            src={props.dataset.audio}
            speed
          />
          <div className="flex gap-4">
            <span className="text-green-400">{props.dataset.status}</span>
            <span>
              <DateFormat date={String(props.dataset.createdAt)} />
            </span>
          </div>
        </div>

        <h2 className="text-xl font-bold text-white py-4">Transcriptions</h2>
        <div className="flex flex-col gap-5">
          {props.dataset.transcripts
            .sort((a, b) => b.id - a.id)
            .map((transcript) => (
              <DatasetHistoryItem transcript={transcript} key={transcript.id} />
            ))}
        </div>
      </div>
    </div>
  );
};
