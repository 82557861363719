import { Spinner } from "@nextui-org/react";

type props = {
  text: string;
};

export const ProcessInfo = (props: props) => (
  <div className="flex w-full justify-between items-center">
    <span className="text-xl text-warning-500">{props.text}</span>{" "}
    <Spinner color="warning" />
  </div>
);
