import {
  InstructionItem,
  InstructionModalButton,
  InstructionsList,
} from "@entities";

export const InstructionPage = () => {
  return (
    <div className="w-full p-6 overflow-y-scroll">
      <div className="flex justify-end">
        <InstructionModalButton />
      </div>
      <div className="border-t-2 mt-6 border-stone-600">
        <InstructionsList />
        <InstructionItem />
      </div>
    </div>
  );
};
