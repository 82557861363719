import { Button, Input } from "@nextui-org/react";
import { IoAdd } from "react-icons/io5";
import { HiOutlineArrowLongUp } from "react-icons/hi2";
import { HiOutlineArrowLongDown } from "react-icons/hi2";
import { GiBurningSkull } from "react-icons/gi";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { useTestHook } from "../hooks/hook";
import { useSearchParams } from "react-router-dom";
import { orderValues, sortOrderKey, useAppStore } from "@shared";
import { useTestStore } from "../model";
import { useEffect, useRef } from "react";

export const TestHeader = () => {
  const { toastId, uploadProgress } = useAppStore();
  const disabled = Boolean(toastId) || uploadProgress !== null;
  const { uplaodTests } = useTestHook();
  const { setSelectTestId, setClear } = useTestStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const sortOrder = searchParams.get(sortOrderKey) || orderValues.ASC;
  const handleChangeSortOrder = () => {
    const newSortOrder =
      sortOrder === orderValues.ASC ? orderValues.DESC : orderValues.ASC;
    setSearchParams({ ...searchParams, [sortOrderKey]: newSortOrder });
  };
  const ref = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (disabled && ref.current) ref.current.value = "";
  }, [disabled]);
  return (
    <div className="flex  gap-4 w-full px-6 py-2 pt-4">
      <div className="relative">
        <Input
          ref={ref}
          onChange={uplaodTests}
          id="file"
          type="file"
          className="absolute hidden"
          accept=".zip,application/zip,application/x-zip-compressed,multipart/x-zip"
        />
        <Button isDisabled={disabled} className="" size="sm">
          <label
            className="cursor-pointer gap-2 px-2 h-full flex items-center"
            htmlFor="file"
          >
            <MdOutlineDriveFolderUpload className="text-xl" />
            <span>IMPORT TESTS</span>
          </label>
        </Button>
      </div>
      <Button
        isDisabled={disabled}
        onClick={() => setSelectTestId(0)}
        size="sm"
      >
        <IoAdd className="text-xl" />
        <span>CREATE TEST</span>
      </Button>
      <Button size="sm" onClick={handleChangeSortOrder} className="flex gap-2">
        {sortOrder === orderValues.ASC ? (
          <HiOutlineArrowLongUp className="text-md" />
        ) : (
          <HiOutlineArrowLongDown className="text-md" />
        )}
        <span>SORT BY ID</span>
      </Button>
      <Button
        onClick={() => setClear(true)}
        variant="solid"
        className="bg-red-600"
        size="sm"
      >
        <GiBurningSkull className="text-xl" />
        <span>CLEAR</span>
      </Button>
    </div>
  );
};
