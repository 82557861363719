import {
  IRateWithTranscript,
  IResult,
  ITranscript,
  IUserData,
  UserItemInfo,
  UserRates,
  UserTestResults,
  UserTranscripts,
} from "@entities";
import { Tab, Tabs } from "@nextui-org/react";
import { ComponentLoader, IRate } from "@shared";
import { Fragment } from "react/jsx-runtime";
import { obj, useGetDataHook } from "../hooks/getData.hook";
import { loadingValues } from "../constants";
import { useEffect } from "react";

type props = {
  wrapperHeight: number;
  userId: number;
  wrapper: HTMLDivElement;
};

export const UserData = (props: props) => {
  const {
    user,
    testResults,
    setTestResults,
    transcripts,
    setTranscripts,
    likes,
    setLikes,
    dislikes,
    setDislikes,
    loading,
    setUser,
    fetched,
  } = useGetDataHook(props.userId, props.wrapperHeight);

  useEffect(() => {
    return () => {
      fetched.current = false;
      setUser(null);
      setTestResults(obj as IUserData<IResult>);
      setTranscripts(obj as IUserData<ITranscript>);
      setLikes(obj as IUserData<IRateWithTranscript>);
      setDislikes(obj as IUserData<IRateWithTranscript>);
    };
  }, [props.userId]);
  return (
    <Fragment>
      <ComponentLoader loading={loading === loadingValues.initialLoading} />

      {user && (
        <UserItemInfo
          user={user}
          transcripts={transcripts.count}
          likes={likes.count}
          dislikes={dislikes.count}
          results={testResults.count}
        />
      )}

      <Tabs variant="underlined" className="pt-4">
        <Tab key="test" title="Test results">
          <UserTestResults
            userId={props.userId}
            wrapper={props.wrapper}
            setTestResults={setTestResults}
            results={testResults.results}
            count={testResults.count}
            limit={testResults.limit}
          />
        </Tab>
        <Tab key="transcript" title="Transcripts">
          <UserTranscripts
            userId={props.userId}
            wrapper={props.wrapper}
            setTranscripts={setTranscripts}
            transcripts={transcripts.results}
            count={transcripts.count}
            limit={transcripts.limit}
          />
        </Tab>
        <Tab key="like" title="Likes">
          <UserRates
            userId={props.userId}
            wrapper={props.wrapper}
            setRates={setLikes}
            limit={likes.limit}
            count={likes.count}
            rates={likes.results}
            url="likes"
          />
        </Tab>
        <Tab key="dislike" title="Dislikes">
          <UserRates
            userId={props.userId}
            wrapper={props.wrapper}
            setRates={setDislikes}
            count={dislikes.count}
            limit={dislikes.limit}
            rates={dislikes.results}
            url="dislikes"
          />
        </Tab>
      </Tabs>
    </Fragment>
  );
};
