import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useState } from "react";
import { useWordsStore } from "../store";
import { useWordsHook } from "../hook";
import { modalMode } from "../types";

export const WordCreateModal = () => {
  const { wordsModal, setWordsModal } = useWordsStore();

  const [value, setValue] = useState("");

  const { createWord } = useWordsHook();

  const handleSubmit = async () => {
    if (!Boolean(value.trim())) return;
    createWord(value.trim().toLowerCase());
    onClose();
  };

  const onClose = () => {
    setValue("");
    setWordsModal(null);
  };
  return (
    <Modal
      backdrop={"blur"}
      isOpen={wordsModal === modalMode.create}
      onClose={onClose}
      className="dark"
      isDismissable={false}
      placement="top-center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              Create word item
            </ModalHeader>
            <ModalBody>
              <div>
                <Input value={value} onValueChange={setValue} />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="flat" onPress={onClose}>
                Close
              </Button>
              <Button color="default" onPress={handleSubmit}>
                Create
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
