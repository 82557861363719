import { useSearchParams } from "react-router-dom";
import { useSearchHook } from "../hook";
import { searchKey } from "@shared";
import { useEffect } from "react";

export const ResultList = () => {
  const { handleSearch, words } = useSearchHook();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get(searchKey);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  return (
    <div className="flex flex-col h-[calc(100vh-112px)] gap-2 p-4  overflow-y-auto">
      {words.map((word) => (
        <div
          className="cursor-pointer hover:bg-zinc-900 rounded-lg px-3"
          key={word.id}
        >
          {word.body}
        </div>
      ))}
    </div>
  );
};
