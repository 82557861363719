import { useDatasetStore } from "@entities";
import { NextUIProvider } from "@nextui-org/react";
import { Loader, ProcessInfo, useAppStore } from "@shared";
import { useEffect } from "react";
import { toast, Toaster } from "sonner";
export const ThemeProvider = ({ children }) => {
  const { error, info, setError, setInfo, toastId } = useAppStore();

  const { undo } = useDatasetStore();
  useEffect(() => {
    if (Object.keys(undo).length === 0) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue =
        "You have unsaved changes. Are you sure you want to leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [undo]);

  useEffect(() => {
    if (Boolean(toastId)) {
      toast(<ProcessInfo text={toastId} />, {
        duration: Infinity,
        position: "bottom-right",
      });
    }
    if (error) {
      toast(error, {
        style: { background: "#18181b" },

        cancelButtonStyle: {
          background: "gray",
        },
        classNames: {
          title: "text-red-400",
        },
        cancel: {
          label: "close",
          onClick: () => setError(null),
        },
      });
      setError(null);
    }
    if (info) {
      toast(info, {
        style: { background: "#18181b" },
        duration: 1000,
        cancelButtonStyle: {
          background: "gray",
        },
        cancel: {
          label: "close",
          onClick: () => setInfo(null),
        },
      });
      setInfo(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, info, toastId]);

  return (
    <NextUIProvider>
      <main className="w-full min-h-screen dark text-foreground min-w-[1125px] bg-background">
        <Loader />
        <Toaster theme="dark" expand={true} position="top-center" />
        {children}
      </main>
    </NextUIProvider>
  );
};
