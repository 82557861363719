import { limit } from "5-entities/words/types";
import { useWordsStore, WordList, WordsHeader } from "@entities";
import { CustomPagination } from "@shared";

export const WordsPage = () => {
  const { totalAmount } = useWordsStore();
  return (
    <div className="w-full">
      <WordsHeader />
      <WordList />
      <CustomPagination total={Math.ceil(totalAmount / limit)} />
    </div>
  );
};
