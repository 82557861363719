import { datasetStatus } from "5-entities/dataset";
import { role, routes } from "@shared";
import { TbSquareLetterWFilled } from "react-icons/tb";
import { GoDatabase } from "react-icons/go";
import { FaUsers } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import { VscLaw } from "react-icons/vsc";
import { GiClawHammer } from "react-icons/gi";
export const adminRoutes = [
  {
    path: routes[role.admin].dataset.path,
    key: "Dataset",
    icon: GoDatabase,
  },
  {
    path: routes[role.admin].users.path,
    key: "Users",
    icon: FaUsers,
  },
  {
    path: routes[role.admin].test.path,
    key: "Test",
    icon: GiClawHammer,
  },
  {
    path: routes[role.admin].settings.path,
    key: "Settings",
    icon: CiSettings,
  },
  {
    path: routes[role.admin].instructions.path,
    key: "Instructions",
    icon: VscLaw,
  },
  {
    path: routes[role.admin].words.path,
    key: "Words",
    icon: TbSquareLetterWFilled,
  },
] as const;

export const adminSideBarRoutes = {
  [routes[role.admin].dataset.path]: [
    {
      path: routes[role.admin].dataset.new.paginationPath,
      to: routes[role.admin].dataset.new.path,
      label: "New",
      quantity: datasetStatus.new,
    },
    {
      path: routes[role.admin].dataset.rejected.paginationPath,
      to: routes[role.admin].dataset.rejected.path,
      label: "Rejected",
      quantity: datasetStatus.rejected,
    },
    {
      path: routes[role.admin].dataset.drafted.paginationPath,
      to: routes[role.admin].dataset.drafted.path,
      label: "Drafted",
      quantity: datasetStatus.drafted,
    },
    {
      path: routes[role.admin].dataset.reviewing.paginationPath,
      to: routes[role.admin].dataset.reviewing.path,
      label: "Reviewing",
      quantity: datasetStatus.reviewing,
    },
    {
      path: routes[role.admin].dataset.processing.paginationPath,
      to: routes[role.admin].dataset.processing.path,
      label: "Processing",
      quantity: datasetStatus.processing,
    },
    {
      path: routes[role.admin].dataset.conflict.paginationPath,
      to: routes[role.admin].dataset.conflict.path,
      label: "Conflict",
      quantity: datasetStatus.conflict,
    },
    {
      path: routes[role.admin].dataset.ready.paginationPath,
      to: routes[role.admin].dataset.ready.path,
      label: "Ready",
      quantity: datasetStatus.ready,
    },
  ],
  [routes[role.admin].users.path]: [
    {
      path: routes[role.admin].users.userList.path,
      label: "List",
    },
    {
      path: routes[role.admin].users.scores.path,
      label: "Scores",
    },
    {
      path: routes[role.admin].users.statistics.path,
      label: "Statistics",
    },
  ],
  [routes[role.admin].test.path]: [
    {
      to: routes[role.admin].test.writer.path,
      path: routes[role.admin].test.writer.paginationPath,
      label: "Writer",
      quantity: role.writer,
    },
    {
      to: routes[role.admin].test.auditor.path,
      path: routes[role.admin].test.auditor.paginationPath,
      label: "Auditor",
      quantity: role.auditor,
    },
  ],
};
