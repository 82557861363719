import { DateFormat, Duration } from "@shared";
import { IUserScore } from "../types";

type props = {
  user: IUserScore;
  transcripts: number;
  likes: number;
  dislikes: number;
  results: number;
};

export const UserItemInfo = (props: props) => {
  return (
    <div className="flex gap-10 px-4">
      <div>
        <div>
          <strong>Username:</strong>{" "}
          <span className="italic">{props.user.username}</span>
        </div>
        <div>
          <strong>Phone:</strong> <span>{props.user.phone}</span>
        </div>
        <div>
          <strong>Role:</strong> <span>{props.user.role}</span>
        </div>
      </div>
      <div>
        <div>
          <strong>Accepted:</strong>{" "}
          <span>
            <Duration seconds={props.user.statistics.accepted} />
          </span>
        </div>
        <div>
          <strong>Rejected:</strong>{" "}
          <span>
            <Duration seconds={props.user.statistics.rejected} />
          </span>
        </div>
        <div>
          <strong>Paid:</strong>{" "}
          <span>
            <Duration seconds={props.user.statistics.paid} />
          </span>
        </div>
      </div>
      <div>
        <div>
          <strong>Checking:</strong> <span>{props.user.score.checking}</span>
        </div>
        <div>
          <strong>Test:</strong> <span>{props.user.score.test}</span>
        </div>
        <div>
          <strong>Reset:</strong> <span>{props.user.score.reset}</span>
        </div>
      </div>
      <div>
        <div>
          <strong>Transcripts:</strong> <span>{props.transcripts}</span>
        </div>
        <div>
          <strong>Likes:</strong> <span>{props.likes}</span>
        </div>
        <div>
          <strong>Dislikes:</strong> <span>{props.dislikes}</span>
        </div>
      </div>
      <div>
        <div>
          <strong>Test results:</strong> <span>{props.results}</span>
        </div>
        <div>
          <strong>Updated:</strong>{" "}
          <span>
            <DateFormat date={String(props.user.updatedAt)} />
          </span>
        </div>
        <div>
          <strong>Created:</strong>{" "}
          <span>
            <DateFormat date={String(props.user.createdAt)} />
          </span>
        </div>
      </div>
    </div>
  );
};
