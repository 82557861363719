import { CorrectWords } from "5-entities/searchCorrectWords/ui";
import { AuditorDatasetItem } from "@entities";
export const AuditorPage = () => {
  return (
    <div className="flex">
      <CorrectWords />
      <div className="flex flex-1 p-6 items-center h-[calc(100vh-64px)]">
        <AuditorDatasetItem />
      </div>
    </div>
  );
};
