import { IResult } from "@entities";
import { AudioPlayer, DateFormat, Transcription } from "@shared";
import { BiSolidDislike, BiSolidLike } from "react-icons/bi";
import { Fragment } from "react/jsx-runtime";

type props = {
  result: IResult;
};

export const UserRateResultItem = ({ result }: props) => {
  return (
    <div key={result.id} className="flex gap-6">
      {result.like || result.dislike ? (
        <Fragment>
          <Transcription className="!bg-black !w-1/2" text={result.test.text} />
          <div>
            <AudioPlayer src={result.test.audio} />
            <div className="pt-4 flex gap-3 items-end px-2">
              <strong>Original rate:</strong>
              <span>
                {result.test.like ? (
                  <BiSolidLike className="text-xl" />
                ) : (
                  <BiSolidDislike className="text-xl" />
                )}
              </span>
            </div>
            <div className="pt-4 flex gap-3 items-end px-2">
              <strong>User rate:</strong>
              <span>
                {result.like ? (
                  <BiSolidLike className="text-xl" />
                ) : (
                  <BiSolidDislike className="text-xl" />
                )}
              </span>
            </div>
            <div className="pt-4 flex gap-3 items-end px-2">
              <strong>Result:</strong>
              <span className="underline italic">{result.status}</span>
            </div>
            <div className="pt-4 flex gap-3 items-end px-2">
              <strong>Created:</strong>
              <DateFormat date={String(result.createdAt)} />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="!w-1/2">
            <h3 className="pb-3 px-2">Original version</h3>
            <Transcription
              className="!bg-black !h-[150px]"
              text={result.test.text}
            />
            <AudioPlayer src={result.test.audio} />
          </div>
          <div className="!w-1/2">
            <h3 className="pb-3 px-2">User version</h3>

            <Transcription
              className="!bg-black !h-[150px]"
              text={result.text}
            />
            <div className="pt-2 flex justify-between px-2">
              <span>
                Result:{" "}
                <span className="italic underline">{result.status}</span>
              </span>
              <span>
                <DateFormat date={String(result.createdAt)} />
              </span>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
