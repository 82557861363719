import { IUser, orderValues } from "@shared";
import { create } from "zustand";
import { IDataset, IDatasetStore, transcriptStatus } from "../types";

export const useDatasetStore = create<IDatasetStore>((set) => ({
  datasets: [],
  setDatasets: (datasets: IDataset[]) => set({ datasets }),
  addDataset(dataset) {},
  updateDataset(dataset) {
    set((state) => ({
      datasets: state.datasets.map((d) =>
        d.id === dataset.id ? { ...d, ...dataset } : d
      ),
    }));
  },
  updateTranscript(id, body) {
    set((state) => {
      return {
        datasets: state.datasets.map((d) =>
          d.id === id
            ? {
                ...d,
                transcripts: d.transcripts.map((t) =>
                  t.status === transcriptStatus.ready ? { ...t, body } : t
                ),
              }
            : d
        ),
      };
    });
  },
  deleteDataset(id) {
    set((state) => ({
      datasets: state.datasets.filter((d) => d.id !== id),
    }));
  },

  amountOfDatasetItems: 0,
  setAmountOfDatasetItems(amountOfDatasetItems) {
    set({ amountOfDatasetItems });
  },

  sortOrder: orderValues.ASC,
  setSortOrder(sortOrder) {
    set({ sortOrder });
  },

  selectedDatasetId: null,
  setSelectedDatasetId(selectedDatasetId) {
    set({ selectedDatasetId });
  },

  clearDataset: false,
  setClearDataset(clearDataset) {
    set({ clearDataset });
  },
  datasetModalId: null,
  setDatasetModalId(datasetModalId) {
    set({ datasetModalId });
  },

  undo: {},
  setUndo(undo) {
    set({ undo });
  },

  loading: false,
  setLoading(loading) {
    set({ loading });
  },
}));
