import {
  AudioPlayer,
  CustomTextarea,
  EmptyDataset,
  ErrorText,
  ShortCutKeys,
  TimeCompleted,
  Timer,
  useTextAreaHook,
} from "@shared";
import { useWriterHook } from "../hooks";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "@nextui-org/react";

const MemoizedTimer = memo(Timer);

export const WriterDatasetItem = () => {
  const {
    setTranscription,
    dataset,
    empty,
    getWriterDataFromLocalStorage,
    setInLocalStorage,
  } = useWriterHook();
  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook(true);
  const [listened, setListened] = useState(false);
  const [played, setPlayed] = useState(false);

  const [timeFinished, setTimeFinished] = useState(false);

  const handleSubmit = () => {
    if (!dataset) return;
    if (determineErrors()) return;
    setTranscription(dataset.id, shortenSpaces(text), dataset.map);
    setText("");
  };

  const handleText = (text: string) => {
    if (!dataset) return;
    if (!played || timeFinished) return;

    setText(text);
    setInLocalStorage(dataset.id, text, {});
  };

  const enabled = text.length > 0 && listened;

  useEffect(() => {
    setListened(false);
    setPlayed(false);
    setTimeFinished(false);
    if (dataset) {
      const data = getWriterDataFromLocalStorage(dataset.id, dataset.text);
      if (!data) return setText(dataset.text);
      if (data.listened) setListened(true);
      if (data.played) setPlayed(true);
      if (Boolean(data.text)) setText(data.text);
    }
  }, [dataset]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.key === "Enter" || event.key === "NumpadEnter") &&
        event.ctrlKey
      ) {
        event.preventDefault();
        if (enabled) handleSubmit();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [enabled, text]);

  const minutes = useMemo(
    () => (dataset ? (Number(dataset.freezedTime) - Date.now()) / 60000 : 30),
    [dataset]
  );

  const onCompleted = useCallback(() => {
    setTimeFinished(true);
  }, []);

  const handleSetPLayed = (argument: boolean) => {
    setPlayed(argument);
    setInLocalStorage(dataset.id, text, { played: argument });
  };

  const handleSetListened = (argument: boolean) => {
    setListened(argument);
    setInLocalStorage(dataset.id, text, { listened: argument });
  };

  if (dataset)
    return (
      <Fragment>
        <TimeCompleted completed={timeFinished} />

        <div className="max-w-[1125px] w-full flex-1 flex flex-col justify-center">
          <div className="flex-1 flex items-center">
            <ErrorText error={error} className="flex-1 !px-0" />
          </div>
          <MemoizedTimer minutes={minutes} onFinish={onCompleted} />
          <CustomTextarea
            error={error}
            avtoFocused
            className="!h-[250px] text-xl p-4"
            value={text}
            setValue={handleText}
          />
          {!timeFinished ? (
            <div className="w-full flex">
              <AudioPlayer
                setListened={handleSetListened}
                setPlayed={handleSetPLayed}
                disableInteraction={!listened}
                src={dataset.audio}
                className="mt-1"
                speed
              />
            </div>
          ) : null}
          <div className="flex-1">
            <ShortCutKeys />
          </div>
        </div>

        <div className="w-full flex justify-end">
          <Button
            className={`${!enabled && "text-gray-500"}`}
            size="sm"
            disabled={!enabled}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </div>
      </Fragment>
    );

  if (empty) return <EmptyDataset />;
};
