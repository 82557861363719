import { useEffect, useRef, useState } from "react";
import { useDatasetStore } from "../model";
import { CiUndo } from "react-icons/ci";
import { RiSpeedLine } from "react-icons/ri";
import { waitTime } from "@shared";
import { useDatasetHook } from "../hooks/hook";
type props = {
  id: number;
};

export const DatasetUndo = (props: props) => {
  const { undo, setUndo } = useDatasetStore();
  const [width, setWidth] = useState(0);
  const time = useRef<NodeJS.Timer>();

  const { confirmDataset } = useDatasetHook();

  useEffect(() => {
    const item = undo[props.id];
    if (!item) return;
    const obj = { ...undo };
    delete obj[props.id];
    const endTime = item.endTime;
    const interval = 50;
    time.current = setInterval(() => {
      const now = Date.now();
      if (now - endTime >= 0) {
        clearInterval(time.current);
        setUndo(obj);
      }
      setWidth(100 - ((endTime - now) * 100) / waitTime);
    }, interval);
    return () => {
      clearInterval(time.current);
    };
  }, [undo]);

  const handleClickUndo = () => {
    clearInterval(time.current);
    setWidth(0);
    const obj = { ...undo };
    delete obj[props.id];
    clearTimeout(undo[props.id].timeout);
    setTimeout(() => {
      setUndo(obj);
    }, 200);
  };

  const handleDo = () => {
    clearInterval(time.current);
    setWidth(100);
    const item = undo[props.id];
    const obj = { ...undo };
    delete obj[props.id];
    clearTimeout(undo[props.id].timeout);
    setTimeout(() => {
      setUndo(obj);
      confirmDataset(props.id, item.rejected, item.text);
    }, 200);
  };

  if (undo[props.id])
    return (
      <div className="absolute w-full h-full z-10 top-0 left-0  flex">
        <div
          style={{ width: `${width}%` }}
          onClick={handleDo}
          className={`transition-width cursor-pointer flex items-center justify-center border-r-2  ${
            undo[props.id].rejected ? "border-r-red-500" : "border-r-green-500"
          } h-full bg-black fire`}
        >
          <RiSpeedLine className="text-2xl z-10" />
        </div>
        <div
          style={{ width: `${100 - width}%` }}
          className="transition-width cursor-pointer flex items-center relative justify-center h-full"
          onClick={handleClickUndo}
        >
          <div className="absolute w-full h-full top-0 left-0 bg-black opacity-20"></div>
          <CiUndo className="text-2xl z-10" />
        </div>
      </div>
    );
};
