import { useParams, useSearchParams } from "react-router-dom";
import { searchKeys, sortValues } from "../types";
import { orderValues, searchKey } from "@shared";

export const useSearchParamsHook = () => {
  const { page } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get(searchKey);

  const orderDirection =
    searchParams.get(searchKeys.orderDirection) || orderValues.ASC;

  const orderBy = searchParams.get(searchKeys.orderBy) || sortValues.sortByWord;

  const handleChangeSort = (orderByParam: string) => () => {
    searchParams.set(
      searchKeys.orderDirection,
      orderDirection === orderValues.ASC ? orderValues.DESC : orderValues.ASC
    );
    searchParams.set(searchKeys.orderBy, orderByParam);
    setSearchParams(searchParams);
  };

  return {
    page,
    orderDirection,
    orderBy,
    handleChangeSort,
    searchParams,
    setSearchParams,
    searchQuery,
  };
};
