import {
  asteriskisWrongPosition,
  hasNonConsecutiveAsterisks,
} from "6-shared/utils";
import { useCallback, useEffect, useRef, useState } from "react";

export const useTextAreaHook = (autoCheck?: boolean) => {
  const [text, setText] = useState("");
  const [error, setError] = useState<string | null>(null);

  const timeout = useRef<NodeJS.Timeout>();

  const determineErrors = useCallback(
    (emptyValueError?: boolean) => {
      let err: null | string = null;
      if (emptyValueError && text === "") {
        err = "Transkript kamida bitta belgidan iborat bo'lishi shart.";
        setError(err);
        return err;
      }

      if (text.includes(".")) {
        err = "Transkriptda nuqta belgisi bo'lishi mumkin emas.";
        setError(err);
        return err;
      }

      if (text.includes("''")) {
        err = "Transkriptda apostrof ketma-ket kelishi mumkin emas.";
        setError(err);
        return err;
      }

      if (/(?<![a-z])\'/.test(text)) {
        err =
          "Transkriptda apostrof faqatgina harf yoki harflardan keyin kelishi mumkin.";
        setError(err);
        return err;
      }

      if (/-\S|\S-/.test(text)) {
        err =
          "Defisning o'ng tomonida va chap tomonida bo'shliq bo'lishi kerak. Faqatgina transkript defis bilan boshlanganda chap tomonida bo'shliq bo'lishi shart emas va transkript defis bilan tugaganda defisning o'ng tomonida bo'shliq bo'lishi shart emas.";

        setError(err);
        return err;
      }
      if (/-\s+-/.test(text)) {
        err = "Transkriptda ketma-ket defis kelishi mumkin emas.";
        setError(err);
        return err;
      }

      if (/(?<![a-z*'])\*/.test(text)) {
        err =
          "Transkriptda * simvoli faqatgina bitta harfdan keyin kelishi mumkin."; //
        setError(err);
        return err;
      }

      if (text.split(" ").find(asteriskisWrongPosition)) {
        err =
          "Transkriptda * simvoli faqatgina bitta harfdan keyin kelishi mumkin.";
        setError(err);
        return err;
      }

      if (text.split(" ").find(hasNonConsecutiveAsterisks)) {
        err = "Bitta so'zda * faqatgina ketma-ket holatda kelishi mumkin.";
        setError(err);
        return err;
      }

      if (/\*{4,}/.test(text)) {
        err = "Transkriptda * simvoli 3 ta dan ko'p kelishi mumkin emas.";
        setError(err);
        return err;
      }

      setError(null);
      return false;
    },
    [text]
  );

  useEffect(() => {
    if (!autoCheck) return;

    clearTimeout(timeout.current);

    if (text.trim() === "") return setError(null);

    timeout.current = setTimeout(() => {
      determineErrors();
    }, 300);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [autoCheck, text]);

  const shortenSpaces = useCallback((text: string) => {
    if (text === " ") return text;
    return text.replace(/\s+/g, " ").trim();
  }, []);

  return { shortenSpaces, determineErrors, text, setText, error };
};
