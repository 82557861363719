import { useInstructionsStore } from "../model";
import { InstructionItem } from "./instructionItem";

export const InstructionsList = () => {
  const { instructions } = useInstructionsStore();
  return (
    <div>
      {instructions
        .sort((a, b) => a.id - b.id)
        .map((instruction) => (
          <InstructionItem instruction={instruction} key={instruction.id} />
        ))}
    </div>
  );
};
