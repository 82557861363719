import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useTestStore } from "../model";
import { useTestHook } from "../hooks/hook";

export const TestDelete = () => {
  const { selectTestId, setSelectTestId, clear, setClear } = useTestStore();
  const { deleteTest, truncateTest } = useTestHook();
  const handleRemove = () => {
    if (clear) truncateTest();
    else if (selectTestId < 0) deleteTest(Math.abs(selectTestId));
    onClose();
  };
  const onClose = () => {
    setSelectTestId(null);
    setClear(false);
  };
  return (
    <Modal
      backdrop={"blur"}
      isOpen={selectTestId < 0 || clear}
      onClose={onClose}
      className="dark"
      isDismissable={false}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              Are you sure?
            </ModalHeader>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={handleRemove}>
                Yes
              </Button>
              <Button color="default" onPress={onClose}>
                No
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
