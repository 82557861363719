import { useCallback, useEffect, useState } from "react";
import { ISettings } from "../types";
import { METHODS, useAppStore, useAxios } from "@shared";
import { useSettingsStore } from "../model";

export const useSettingsHook = () => {
  const { fetchData } = useAxios();

  const { setInfo, setError } = useAppStore();

  const { setSettings, settings } = useSettingsStore();

  const getSettings = useCallback(async () => {
    const response = await fetchData<ISettings[]>(
      "/settings",
      METHODS.GET,
      null,
      false
    );
    if (response.data) setSettings(response.data);
  }, []);

  const updateSetting = useCallback(async () => {
    const response = await fetchData<ISettings[]>(
      "/settings",
      METHODS.PUT,
      settings,
      false
    );
    if (response.data) setInfo("Settings updated");
    else setError("Something went wrong");
  }, [settings]);

  return { getSettings, updateSetting };
};
