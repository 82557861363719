import { Link, useLocation } from "react-router-dom";
import { adminRoutes } from "../model";

export const AdminNavigation = () => {
  const { pathname } = useLocation();
  return (
    <div className="gap-8 flex">
      {adminRoutes.map((adminRoute) => (
        <Link
          to={adminRoute.path}
          key={adminRoute.key}
          className={`${
            pathname.includes(adminRoute.path) ? " " : "text-gray-500"
          }`}
        >
          <adminRoute.icon title={adminRoute.key} className="text-2xl" />
        </Link>
      ))}
    </div>
  );
};
