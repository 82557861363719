import { useEffect, useState } from "react";
import { datasetStatus, datasetStatusValueType, sortTypeKeys } from "../types";
import { useParams, useSearchParams } from "react-router-dom";
import { useDatasetStore } from "../model";
import { useDatasetHook } from "../hooks/hook";
import { DatasetItem } from "./datasetItem";
import { ListLoader, orderValues, sortOrderKey, sortTypeKey } from "@shared";
import { useDatasetDurationHook } from "5-entities/datasetDuration";

type Props = {
  status: datasetStatusValueType;
};

export const DatasetList = (props: Props) => {
  const { page } = useParams();
  const [searchParams] = useSearchParams();
  const sortOrderValue = searchParams.get(sortOrderKey) || orderValues.ASC;
  const sortTypeValue = searchParams.get(sortTypeKey) || sortTypeKeys.id;
  const { datasets, loading, setLoading } = useDatasetStore();
  const { getDataset } = useDatasetHook();
  const { getAudioDuration } = useDatasetDurationHook();
  // const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async function () {
      await getAudioDuration();
      await getDataset(
        Number(page) - 1,
        sortTypeValue,
        sortOrderValue,
        props.status
      );
      setLoading(false);
    })();

    return () => {
      setLoading(true);
    };
  }, [props.status, sortOrderValue, sortTypeValue, page]);

  return (
    <div className="h-[calc(100vh-164px)] relative overflow-y-scroll px-6 pt-2">
      <ListLoader
        loading={loading}
        height={props.status === datasetStatus.conflict && "h-[100px]"}
      />
      {!loading &&
        datasets.map((dataset, index) => (
          <DatasetItem index={index} dataset={dataset} key={dataset.id} />
        ))}
    </div>
  );
};
