import {
  AudioPlayer,
  EmptyDataset,
  ShortCutKeys,
  TimeCompleted,
  Timer,
  Transcription,
} from "@shared";
import { useAuditorHook } from "../hooks";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@nextui-org/react";
import { BiDislike, BiLike } from "react-icons/bi";

const MemoizedTimer = memo(Timer);

export const AuditorDatasetItem = () => {
  const {
    dataset,
    rateDatasetItem,
    empty,
    getAuditorDataFromLocalStorage,
    setInLocalStorage,
  } = useAuditorHook();
  const [played, setPlayed] = useState(false);
  const [listened, setListened] = useState(false);
  const [rate, setRate] = useState(0);

  const [timeFinished, setTimeFinished] = useState(false);

  const handleRate = (value: number) => () => {
    setRate((prev) => {
      const newValue = prev === value ? 0 : value;
      setInLocalStorage(dataset.id, newValue, {});
      return newValue;
    });
  };

  const nextDisabled = rate === 0;

  const handleSubmit = () => {
    rateDatasetItem(dataset.transcripts[0].id, dataset.id, rate, dataset.map);
  };

  useEffect(() => {
    setRate(0);
    setListened(false);
    setPlayed(false);
    if (dataset) {
      const data = getAuditorDataFromLocalStorage(dataset.id);
      if (!data) return setRate(0);
      if (data.listened) setListened(true);
      if (data.played) setPlayed(true);
      if (Boolean(data.rate)) setRate(data.rate);
    }
  }, [dataset]);

  const minutes = useMemo(
    () => (dataset ? (Number(dataset.freezedTime) - Date.now()) / 60000 : 10),
    [dataset]
  );
  const onCompleted = useCallback(() => {
    setTimeFinished(true);
  }, []);

  const handleSetPlayed = (argument: boolean) => {
    setPlayed(argument);
    setInLocalStorage(dataset.id, rate, { played: argument });
  };

  const handleSetListened = (argument: boolean) => {
    setListened(argument);
    setInLocalStorage(dataset.id, rate, { listened: argument });
  };

  if (dataset && dataset.transcripts && dataset.transcripts.length > 0)
    return (
      <div className="flex flex-col h-full items-center w-full">
        <TimeCompleted completed={timeFinished} />
        <div className="flex-1"></div>

        <div className="flex-1 max-w-[1125px] w-full flex flex-col justify-center">
          <MemoizedTimer minutes={minutes} onFinish={onCompleted} />

          <Transcription text={dataset.transcripts[0].body} />
          <div className="flex items-center flex-row-reverse justify-between">
            <div className="mt-4 flex items-center bg-zinc-900 w-min rounded-full">
              <Button
                isIconOnly
                variant="shadow"
                size="sm"
                onClick={handleRate(-1)}
                disabled={!played}
                color={rate === -1 ? "danger" : "default"}
                className={`rounded-full w-[100px] uppercase rounded-tr-none rounded-br-none ${
                  !played ? "text-gray-500" : ""
                }`}
              >
                {/* <BiDislike className="text-2xl" /> */}
                xato
              </Button>
              <div className="w-[1px] h-[30px] bg-gray-500"></div>
              <Button
                isIconOnly
                variant="shadow"
                size="sm"
                onClick={handleRate(1)}
                disabled={!listened}
                color={rate === 1 ? "success" : "default"}
                className={`rounded-full w-[100px] uppercase rounded-tl-none rounded-bl-none ${
                  !listened ? "text-gray-500" : ""
                }`}
              >
                {/* <BiLike className="text-2xl" /> */}
                to'g'ri
              </Button>
            </div>
            {!timeFinished ? (
              <AudioPlayer
                setListened={handleSetListened}
                setPlayed={handleSetPlayed}
                disableInteraction={!listened}
                src={dataset.audio}
                className="mt-4 !pt-0"
                speed
              />
            ) : null}
          </div>
          <div className="flex-1">
            <ShortCutKeys />
          </div>
        </div>

        <div className="flex w-full flex-1 justify-end items-end">
          {!timeFinished ? (
            <Button
              onClick={handleSubmit}
              disabled={nextDisabled}
              className={`${nextDisabled ? "text-gray-500" : ""}`}
              size="sm"
            >
              Next
            </Button>
          ) : null}
        </div>
      </div>
    );

  if (empty) return <EmptyDataset />;
};
