import { PageProps, routes, useAppStore } from "@shared";
import { Header, Modals, Sidebar } from "@widgets";
import { Navigate, Outlet } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export const Admin = (props: PageProps) => {
  const { user } = useAppStore();

  if (!user || user.role !== props.role)
    return <Navigate to={routes.login.key} />;

  return (
    <Fragment>
      <Header />
      <div className="h-[calc(100vh-66px)]  flex">
        <Sidebar />
        <Outlet />
      </div>
      <Modals />
    </Fragment>
  );
};
