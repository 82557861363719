import { Duration } from "@shared";
import { useDurationStore } from "../model";

export const AdminDatasetDuration = () => {
  const { duration } = useDurationStore();

  if (duration)
    return (
      <div className="flex gap-5 text-gray-400">
        {Object.keys(duration).map((key) => (
          <div className="flex gap-2 text-sm items-center" key={key}>
            <span className="uppercase ">{key}:</span>

            <span className="text-white">
              <Duration seconds={duration[key]} />
            </span>
          </div>
        ))}
      </div>
    );

  return <div></div>;
};
