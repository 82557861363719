/* eslint-disable react-hooks/exhaustive-deps */
import {
  DatasetHistory,
  IDataset,
  useDatasetHook,
  useDatasetStore,
} from "@entities";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { ComponentLoader, useAppStore } from "@shared";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const DatasetItemModal = () => {
  const { loading } = useAppStore();
  const { datasetModalId, setDatasetModalId } = useDatasetStore();
  const [dataset, setDataset] = useState<IDataset | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    onClose();
  }, [pathname]);

  const { getDatasetById } = useDatasetHook();

  const onClose = () => {
    setDatasetModalId(null);
    setDataset(null);
  };

  useEffect(() => {
    if (!datasetModalId) return setDataset(null);
    (async function () {
      const datasetItem = await getDatasetById(datasetModalId);
      if (datasetItem) setDataset(datasetItem);
    })();
    return () => {};
  }, [datasetModalId]);

  return (
    <Modal
      size={"full"}
      scrollBehavior="inside"
      isOpen={Boolean(datasetModalId)}
      onClose={onClose}
      isDismissable={true}
    >
      <ModalContent className="dark text-white">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Dataset item
            </ModalHeader>
            <ModalBody>
              <ComponentLoader loading={loading} />
              {dataset && <DatasetHistory dataset={dataset} />}
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
