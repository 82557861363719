import {
  checkForDataInLocalStorage,
  getInfoFromLocalStorage,
  keyForAuditorData,
  localStorageKey,
  METHODS,
  useAxios,
} from "@shared";
import { useCallback, useEffect, useState } from "react";
import { IDatasetItem, ILocatStorageData, IStatistics } from "../types";
import { useAuditorStore } from "../model";

export const useAuditorHook = () => {
  const { fetchData } = useAxios();

  const [dataset, setDataset] = useState<null | IDatasetItem>(null);
  const [empty, setEmpty] = useState(false);

  const { setStatistics } = useAuditorStore();

  useEffect(() => {
    getDatasetItem();
    refreshStatistics();
  }, []);

  // const revertDatasetStatus = useCallback(async () => {
  //   if (!dataset) return;
  //   await fetchData(`/dataset/revert/${dataset.id}`, METHODS.PUT, {
  //     map: dataset.map,
  //   });
  // }, [dataset]);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", revertDatasetStatus);
  //   return () => {
  //     window.removeEventListener("beforeunload", revertDatasetStatus);
  //   };
  // }, [revertDatasetStatus]);

  const getDatasetItem = useCallback(async () => {
    const response = await fetchData(
      `/dataset/auditor${getInfoFromLocalStorage()}`
    );
    acceptResponse(response);
  }, []);

  const rateDatasetItem = useCallback(
    async (
      transcriptId: number,
      datasetId: number,
      rate: number,
      map: number
    ) => {
      setDataset(null);
      localStorage.removeItem(keyForAuditorData);
      const response = await fetchData(`/dataset/auditor`, METHODS.POST, {
        transcriptId,
        rate: rate === 1,
        datasetId,
        map,
      });
      refreshStatistics();
      acceptResponse(response);
    },
    []
  );

  const refreshStatistics = useCallback(async () => {
    const response = await fetchData<IStatistics>(
      "/dataset/auditor/statistics",
      METHODS.GET,
      null,
      false
    );
    if (response.data) setStatistics(response.data);
  }, []);

  const acceptResponse = (response: {
    data: IDatasetItem;
    message: string;
  }) => {
    if (response && Boolean(response.data)) {
      localStorage.setItem(
        localStorageKey,
        `${response.data.id}-${response.data.map}`
      );

      if (+checkForDataInLocalStorage(keyForAuditorData) !== response.data.id)
        setInLocalStorage(response.data.id, 0, {});

      setDataset(response.data);
    } else setEmpty(true);
  };

  const getAuditorDataFromLocalStorage = useCallback(
    (id: number, rate: number = 0): ILocatStorageData => {
      const defaultValue = {
        id,
        listened: false,
        played: false,
        rate,
      };
      try {
        const data = localStorage.getItem(keyForAuditorData);
        if (!data) return defaultValue;
        const auditorData: ILocatStorageData = JSON.parse(data);
        if (auditorData.id !== id) return defaultValue;
        return { ...auditorData };
      } catch (error) {
        return defaultValue;
      }
    },
    []
  );

  const setInLocalStorage = useCallback(
    (id: number, rate: number, data: Partial<ILocatStorageData>) => {
      const storedData = getAuditorDataFromLocalStorage(id, rate);
      localStorage.setItem(
        keyForAuditorData,
        JSON.stringify({ ...storedData, ...data, rate })
      );
    },
    []
  );

  return {
    dataset,
    rateDatasetItem,
    empty,
    setInLocalStorage,
    getAuditorDataFromLocalStorage,
  };
};
