/* eslint-disable react-hooks/exhaustive-deps */
import { IUser, routes, useAppStore, useAxios } from "@shared";
import { useEffect, useState } from "react";
import { useProcessHook } from "./process.hook";

export const useAuthHook = () => {
  const [permission, setPermission] = useState(false);
  const { fetchData } = useAxios();
  const { setUser } = useAppStore();
  const { getTaskInfo } = useProcessHook();
  useEffect(() => {
    (async () => {
      const res = await fetchData<IUser>("/auth/check", "GET");

      setUser(res.data);
      res.data && res.data.taskId && getTaskInfo(res.data.taskId);
      setPermission(true);
    })();
  }, []);

  return { permission };
};
