import { Fragment } from "react/jsx-runtime";

type props = {
  date: string;
};
export const DateFormat = (props: props) => (
  <Fragment>
    {new Intl.DateTimeFormat("uz-Uz").format(new Date(props.date))}
  </Fragment>
);
