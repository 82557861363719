import { Button, Skeleton } from "@nextui-org/react";
import { useRef, useState, useEffect, Dispatch, SetStateAction } from "react";
import { IoIosPlay } from "react-icons/io";
import { FaPause } from "react-icons/fa";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { Duration } from "../duration";
import { audioSpeeds } from "6-shared/constants";
type Props = {
  src: string;
  disableInteraction?: boolean;
  disableKeyboardControls?: boolean;
  setListened?: Dispatch<SetStateAction<boolean>>;
  setPlayed?: Dispatch<SetStateAction<boolean>>;
  className?: string;
  speed?: boolean;
};
const development = process.env.NODE_ENV !== "production";

export const AudioPlayer = (props: Props) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentTime, setCurrentTime] = useState(0); // Track current playback time
  const [duration, setDuration] = useState(0); // Track audio duration
  const [isPlaying, setIsPlaying] = useState(false);
  const [left, setLeft] = useState(0);
  const [lastUpdatedMaxTime, setLastUpdatedMaxTime] = useState(0);

  const [audioSpeed, setAudioSpeed] = useState(audioSpeeds.NORMAL);

  const wrapper = useRef<null | HTMLDivElement>(null);
  // Handle when audio is fully listened
  const handleAudioEnd = () => {
    props.setListened && props.setListened(true);
  };

  // Method to change playback time
  const changeTime = (time: number) => {
    if (audioRef.current) {
      if (props.disableInteraction && time > lastUpdatedMaxTime) return;
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  const toggleAudioPlayBack = async () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        await audioRef.current.play();
      }
    }
  };
  const audioPlaybackForward = () => {
    if (duration && audioRef.current) {
      const time = currentTime + 1;
      if (time > duration) {
        if (props.disableInteraction && time > lastUpdatedMaxTime) return;
        audioRef.current.pause();
        changeTime(0);
        setCurrentTime(0);
        setLastUpdatedMaxTime(duration);
        setLeft(0);
        setIsPlaying(false);
      } else changeTime(time);
    }
  };
  const audioPlaybackBackward = () => changeTime(Math.max(currentTime - 1, 0));

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const wrapperRect = wrapper.current?.getBoundingClientRect(); // Check if wrapper exists
    if (wrapperRect) {
      const mouseXRelativeToWrapper = event.clientX - wrapperRect.left; // Calculate mouse X position relative to wrapper

      changeTime(
        (mouseXRelativeToWrapper / wrapper.current.clientWidth) *
          audioRef.current.duration
      );
    }
  };

  useEffect(() => {
    if (props.disableKeyboardControls) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === " " && event.shiftKey) {
        event.preventDefault();
        toggleAudioPlayBack();
      }
      if (event.key === "ArrowRight" && event.shiftKey) {
        event.preventDefault();
        audioPlaybackForward();
      }
      if (event.key === "ArrowLeft" && event.shiftKey) {
        event.preventDefault();
        audioPlaybackBackward();
      }
      if (event.key === "ArrowDown" && event.shiftKey) {
        event.preventDefault();
        changeTime(0);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isPlaying,
    audioPlaybackForward,
    audioPlaybackBackward,
    props.disableKeyboardControls,
  ]);

  // Listen to time updates
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateDuration = () => {
        setDuration(audio.duration);
      };

      const updateTime = () => {
        setCurrentTime(audio.currentTime);
        setLastUpdatedMaxTime((prev) => Math.max(prev, audio.currentTime));
        setLeft(201 * (audio.currentTime / audio.duration));
      };

      const setPlaying = () => {
        props.setPlayed && props.setPlayed(true);
        setIsPlaying(true);
      };
      const setPause = () => setIsPlaying(false);

      audio.addEventListener("playing", setPlaying);

      audio.addEventListener("pause", setPause);

      audio.addEventListener("timeupdate", updateTime);
      audio.addEventListener("loadedmetadata", updateDuration);
      audio.addEventListener("ended", handleAudioEnd);

      return () => {
        audio.removeEventListener("timeupdate", updateTime);
        audio.removeEventListener("loadedmetadata", updateDuration);
        audio.removeEventListener("ended", handleAudioEnd);
        audio.removeEventListener("playing", setPlaying);
        audio.removeEventListener("pause", setPause);
      };
    }
  }, [audioRef]);

  const changeAudioSpeed = (speed: number) => () => {
    audioRef.current.playbackRate = speed;
    setAudioSpeed(speed);
  };

  return (
    <div
      className={`flex flex-col relative min-w-[380px] h-[32px] items-center  pt-2 ${props.className}`}
    >
      <audio
        ref={audioRef}
        src={
          (development ? process.env.REACT_APP_URL : "") +
          "/static/" +
          props.src?.replaceAll("/", "**")
        }
        controls
        className="hidden"
      />

      <Skeleton
        className={` top-0 w-full  rounded-full  absolute transition-all duration-500 ${
          duration ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="h-[32px] rounded-lg bg-default-300"></div>
      </Skeleton>

      {duration && (
        <div className="w-full flex items-center">
          <div className="flex">
            <Button
              size="sm"
              isIconOnly
              variant="light"
              className="rounded-full"
              onClick={audioPlaybackBackward}
            >
              <AiOutlineBackward className="text-xl" />
            </Button>
            <Button
              size="sm"
              isIconOnly
              variant="light"
              onClick={toggleAudioPlayBack}
              className="rounded-full"
            >
              {isPlaying ? (
                <FaPause className="text-md" />
              ) : (
                <IoIosPlay className="text-xl" />
              )}
            </Button>
            <Button
              onClick={audioPlaybackForward}
              size="sm"
              isIconOnly
              variant="light"
              className={`rounded-full`}
            >
              <AiOutlineForward className="text-xl" />
            </Button>
          </div>

          <div className="flex items-center ">
            <span className="text-white w-[35px] !text-xs">
              <Duration seconds={Number(currentTime) || 0} format="mm:ss" />{" "}
            </span>
            <div
              className="flex-1 relative mx-1 w-[206px] cursor-pointer"
              onClick={handleClick}
              ref={wrapper}
            >
              <div
                style={{ left: left + "px" }}
                className={`absolute left-2 transition-all rounded-3xl h-[10px] w-[5px] bg-white`}
              ></div>
              <div className=" rounded-2xl h-[10px] bg-zinc-700"></div>
            </div>
            <span className="text-white w-[35px] text-xs">
              <Duration seconds={Number(duration) || 0} format="mm:ss" />
            </span>
          </div>

          {props.speed && (
            <div className="font-mono text-xs flex gap-2 px-3">
              <div
                onClick={changeAudioSpeed(audioSpeeds.SLOW)}
                className={`${
                  audioSpeed !== audioSpeeds.SLOW && "text-neutral-600"
                }  cursor-pointer`}
              >
                0.5x
              </div>
              <div
                onClick={changeAudioSpeed(audioSpeeds.NORMAL)}
                className={`${
                  audioSpeed !== audioSpeeds.NORMAL && "text-neutral-600"
                }  cursor-pointer`}
              >
                1x
              </div>
              <div
                onClick={changeAudioSpeed(audioSpeeds.FAST)}
                className={`${
                  audioSpeed !== audioSpeeds.FAST && "text-neutral-600"
                }  cursor-pointer`}
              >
                2x
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
