/* eslint-disable react-hooks/exhaustive-deps */
import { useUsersStore } from "@entities";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { Fragment, useEffect, useRef } from "react";
import { UserData } from "./userData";
import { useLocation } from "react-router-dom";

export const UserItemModal = () => {
  const { userModalId, setUserModalId } = useUsersStore();

  const wrapper = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    onClose();
  }, [pathname]);

  const onClose = () => setUserModalId(null);

  // const fetchMoreData = async () => {
  //   if (testResults.results.length === testResults.count) return;
  //   if (loading === 2 || loading === 1) return;
  //   setLoading(2);
  //   const tests = await getUserData<ITestResultsData>(
  //     userModalId,
  //     testResults.results.length,
  //     testResults.limit,
  //     "testResults"
  //   );
  //   setLoading(0);

  //   setTestResults((prev) => ({
  //     ...prev,
  //     results: [...prev.results, ...tests.results],
  //   }));
  // };

  // useEffect(() => {
  //   if (!wrapper.current) return;

  //   function onScroll(event: Event) {
  //     const element = event.target as HTMLDivElement;
  //     if (element.scrollHeight - element.scrollTop === element.clientHeight) {
  //       fetchMoreData();
  //     }
  //   }

  //   wrapper.current.addEventListener("scroll", onScroll);
  //   return () => {
  //     wrapper.current?.removeEventListener("scroll", onScroll);
  //   };
  // }, [wrapper, wrapper.current, ]);

  return (
    <Modal
      size={"full"}
      isOpen={Boolean(userModalId)}
      onClose={onClose}
      isDismissable={true}
    >
      <ModalContent className="dark text-white">
        {(onClose) => (
          <Fragment>
            <ModalHeader className="flex flex-col gap-1">
              User history
            </ModalHeader>
            <ModalBody>
              <div
                ref={wrapper}
                className="h-[calc(100vh-80px)]  overflow-y-scroll"
              >
                {Boolean(userModalId) && wrapper.current && (
                  <UserData
                    userId={userModalId}
                    wrapperHeight={wrapper.current.scrollHeight}
                    wrapper={wrapper.current}
                  />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </Fragment>
        )}
      </ModalContent>
    </Modal>
  );
};
