import {
  METHODS,
  orderValues,
  role,
  roleType,
  routes,
  sortOrderKey,
  useAppStore,
  useAxios,
  useProcessHook,
} from "@shared";
import { useCallback, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ITest } from "../types";
import { useTestStore } from "../model";
import { DatasetUploadProgress } from "6-shared/ui/processInfo/uploadProgress";
import { toast } from "sonner";

export const useTestHook = () => {
  const { fetchData } = useAxios();

  const { setError, setInfo, setUploadProgress } = useAppStore();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const determineRole = pathname.split("/")[3];

  const orderValue = searchParams.get(sortOrderKey) || orderValues.ASC;

  const { page } = useParams();

  const { setTests, setQuantityTests, updateTest } = useTestStore();

  const { getTaskInfo } = useProcessHook();

  const getAllTests = useCallback(
    async (roleValue: string, offset: number, order: string) => {
      const response = await fetchData<{
        count: number;
        totalAuditors: number;
        totalWriters: number;
        test: ITest[];
      }>(
        "/test/admin",
        METHODS.POST,
        {
          role: roleValue,
          offset,
          order,
        },
        false
      );

      if (response.data) {
        setTests(response.data.test);
        setQuantityTests(
          {
            [role.auditor]: response.data.totalAuditors,
            [role.writer]: response.data.totalWriters,
          },
          response.data.count
        );
        return;
      }

      setError(response.message);
    },
    [determineRole]
  );

  const refreshPage = (mode: roleType = determineRole as roleType) => {
    const path = routes[role.admin].test[mode].path;
    if (pathname === path) {
      setSearchParams({ ...searchParams, [sortOrderKey]: orderValues.DESC });
      getAllTests(mode, Number(page) - 1, orderValues.DESC);
    } else navigate(`${path}?${sortOrderKey}=${orderValues.DESC}`);
  };

  const uplaodTests = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      const formdata = new FormData();

      formdata.append("file", file);
      setUploadProgress(0);
      const toastId = toast(<DatasetUploadProgress />, {
        duration: Infinity,
        position: "bottom-right",
      });

      const response = await fetchData(
        "/test/bulk",
        METHODS.POST,
        formdata,
        false,
        {},
        true,
        setUploadProgress
      );
      event.target.value = "";

      setUploadProgress(100);
      setTimeout(() => {
        setUploadProgress(null);
        toast.dismiss(toastId);
        if (response.data) getTaskInfo(String(response.data.id));
        else setError(response.message);
      }, 500);
    },
    [refreshPage]
  );

  const createTest = useCallback(
    async (data: FormData, mode: roleType) => {
      const response = await fetchData<ITest>("/test", METHODS.POST, data);
      if (response.data) {
        setInfo("Test created");
        return refreshPage(mode);
      }
      setError(response.message);
    },
    [refreshPage]
  );

  const editTest = useCallback(
    async (
      id: number,
      text: string,
      initialText: string,
      like: boolean = false,
      dislike: boolean = false
    ) => {
      const response = await fetchData(
        `/test/${id}`,
        METHODS.PUT,
        { text, like, dislike, initialText },
        false
      );
      if (response.data) {
        updateTest(response.data);
        setInfo("Test updated");
      } else setError(response.message);
    },
    []
  );

  const deleteTest = useCallback(
    async (id: number) => {
      await fetchData("/test/" + id, METHODS.DELETE, null, false);
      setInfo("Test deleted");
      getAllTests(determineRole, Number(page) - 1, orderValue);
    },
    [pathname, page, orderValue]
  );

  const truncateTest = useCallback(async () => {
    const response = await fetchData("/test/clear", METHODS.POST);
    if (response.data) {
      setInfo("Tets truncated");
      refreshPage();
    } else setError(response.message);
  }, [refreshPage]);

  return {
    uplaodTests,
    getAllTests,
    determineRole,
    orderValue,
    page,
    createTest,
    editTest,
    deleteTest,
    truncateTest,
  };
};
