import { useScrollHook } from "4-features/users/hooks/scroll.hook";
import {
  ITranscript,
  IUserData,
  useDatasetStore,
  useUsersHook,
  useUsersStore,
} from "@entities";
import {
  AudioPlayer,
  DateFormat,
  InfiniteSrollLoader,
  Transcription,
} from "@shared";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";

type props = {
  transcripts: ITranscript[];
  count: number;
  limit: number;
  userId: number;
  wrapper: HTMLDivElement;
  setTranscripts: Dispatch<SetStateAction<IUserData<ITranscript>>>;
};

export const UserTranscripts = (props: props) => {
  const { setDatasetModalId } = useDatasetStore();
  const { setUserModalId } = useUsersStore();

  const handleSetDatasetModalId = (id: number) => () => {
    setUserModalId(null);
    setDatasetModalId(id);
  };
  const handleSetUserModalId = (id: number) => () => {
    setUserModalId(null);
    setUserModalId(id);
  };

  const [loading, setLoading] = useState(false);
  const { getUserData } = useUsersHook();
  const fetchMoreData = async () => {
    if (props.transcripts.length === props.count || loading) return;
    setLoading(true);

    const transcripts = await getUserData<IUserData<ITranscript>>(
      props.userId,
      props.transcripts.length,
      props.limit,
      "transcripts"
    );
    setLoading(false);

    props.setTranscripts((prev) => ({
      ...prev,
      results: [...prev.results, ...transcripts.results],
    }));
  };

  useScrollHook(props.wrapper, fetchMoreData);

  return (
    <Fragment>
      {props.transcripts.map(
        (transcript) =>
          transcript.dataset && (
            <div key={transcript.id} className="flex gap-2">
              <div className="w-2/3 flex gap-2">
                <div className="w-1/2">
                  <h3
                    className="px-2 pb-3 cursor-pointer"
                    onClick={handleSetDatasetModalId(transcript.datasetId)}
                  >
                    Dataset: {transcript.datasetId}
                  </h3>
                  <Transcription
                    className="!bg-black "
                    text={transcript.dataset.text}
                  />
                </div>
                <div className="w-1/2">
                  <h3 className="px-2 pb-3">User text: {transcript.id}</h3>
                  <Transcription
                    className="!bg-black "
                    text={transcript.body}
                  />
                </div>
              </div>
              <div className="w-1/3">
                <AudioPlayer src={transcript.dataset.audio} />
                <div className="pt-4 px-2">
                  Rate amount: {transcript.rateAmount}
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div>Liked: </div>
                  <div className="flex flex-wrap gap-2">
                    {transcript.likes.map((rate) => (
                      <div
                        onClick={handleSetUserModalId(rate.userId)}
                        className={`italic cursor-pointer text-blue-300`}
                        key={rate.id}
                      >
                        {rate.user.username},
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div>Disliked: </div>
                  <div className="flex flex-wrap gap-2">
                    {transcript.dislikes.map((rate) => (
                      <div
                        onClick={handleSetUserModalId(rate.userId)}
                        className={`italic cursor-pointer text-blue-300`}
                        key={rate.id}
                      >
                        {rate.user.username},
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div>Dataset status: </div>
                  <div className="flex gap-2">{transcript.dataset.status}</div>
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div>Transcript status: </div>
                  <div className="flex gap-2">{transcript.status}</div>
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div>Created: </div>
                  <DateFormat date={String(transcript.createdAt)} />
                </div>
              </div>
            </div>
          )
      )}
      <InfiniteSrollLoader loading={loading} />
    </Fragment>
  );
};
