import { METHODS, useAppStore, useAxios } from "@shared";
import { useUsersStore } from "../model";
import { useCallback, useState } from "react";
import { IUserScore, UserCreationFormInputs } from "../types";
import { UseFormSetValue } from "react-hook-form";

export const useUsersHook = () => {
  const [message, setMessage] = useState<null | string>(null);
  const { setUsers, addUser, updateUser, deleteUser, updatePaid } =
    useUsersStore();

  const { setInfo, setError } = useAppStore();

  const { fetchData } = useAxios();

  const getUsers = useCallback(async (loading?: boolean) => {
    const response = await fetchData<IUserScore[]>(
      "/user/users",
      METHODS.GET,
      null,
      loading
    );

    if (response.message === "OK") setUsers(response.data);
  }, []);

  const createUser = useCallback(
    (setValue: UseFormSetValue<UserCreationFormInputs>) =>
      async (data: UserCreationFormInputs) => {
        const response = await fetchData<IUserScore>(
          "/user/users",
          METHODS.POST,
          data,
          false
        );
        if (!response.data) return setMessage(response.message);

        setValue("password", "");
        setValue("username", "");
        setValue("phone", "");
        setMessage(null);
        addUser(response.data);
        setInfo("User created");
      },
    []
  );

  const editUser = useCallback(
    async (id: number, data: Partial<UserCreationFormInputs>) => {
      const response = await fetchData<IUserScore>(
        "/user/users/" + id,
        METHODS.PUT,
        data,
        false
      );
      if (response.data) {
        updateUser(response.data);
        return setInfo("User updated");
      }

      updateUser({ id, ...data } as IUserScore, true);
      setError(response.message);
    },
    []
  );

  const removeUser = useCallback(async (id: number) => {
    const response = await fetchData<null>(
      "/user/users/" + id,
      METHODS.DELETE,
      null,
      false
    );

    if (response.message === "OK") {
      deleteUser(id);
      return setInfo("User deleted");
    }

    setError(response.message);
  }, []);

  const resetUser = useCallback(async (id: number) => {
    const response = await fetchData<null>(
      "/user/users/score/" + id,
      METHODS.PUT
    );
    if (response.data) return await getUsers();
    setError(response.message);
  }, []);

  const updatePaidValue = useCallback(async (id: number, hours: number) => {
    await fetchData(`/user/statistics/${id}`, METHODS.PUT, { hours }, false);
    console.log(hours);
    updatePaid(id, hours);
    setInfo("Updated");
  }, []);

  const getUserHistory = useCallback(async (id: number) => {
    const response = await fetchData<IUserScore>(
      `/user/history/${id}`,
      METHODS.GET,
      null,
      false
    );
    return response.data;
  }, []);

  const getUserData = useCallback(
    async <T = any>(
      id: number,
      offset: number,
      limit: number,
      url: string
    ): Promise<T> => {
      const response = await fetchData<T>(
        `user/${url}/${id}/${offset}/${limit}`,
        METHODS.GET,
        null,
        false
      );
      if (response && Boolean(response.data)) return response.data;
      return { results: [], count: 0 } as T;
    },
    []
  );

  return {
    getUsers,
    createUser,
    message,
    editUser,
    removeUser,
    resetUser,
    updatePaidValue,
    getUserHistory,
    getUserData,
  };
};
