import { METHODS, useAxios } from "@shared";
import { useCallback } from "react";
import { useDurationStore } from "../model";
import { IDuration } from "../types";

export const useDatasetDurationHook = () => {
  const { fetchData } = useAxios();
  const { updateDuration } = useDurationStore();

  const getAudioDuration = useCallback(async () => {
    const response = await fetchData<{
      duration: IDuration;
      quantity: IDuration;
    }>("/dataset/duration", METHODS.GET, null, false);
    if (response.data)
      updateDuration(response.data.duration, response.data.quantity);
  }, []);

  return { getAudioDuration };
};
