import { IInstruction } from "../types";

type Props = {
  instruction: IInstruction;
};

export const InstructionItemModal = (props: Props) => {
  return (
    <div className="flex">
      <div className="w-1/5 p-5 border-l-2 border-b-2 font-bold border-stone-600 flex flex-col gap-2">
        <h2>{props.instruction.title}</h2>
      </div>
      <div className="w-4/5 p-5 border-r-2 border-l-2 border-b-2 border-stone-600">
        {/* <p className="w-full whitespace-pre">{props.instruction.description}</p> */}
        <div
          dangerouslySetInnerHTML={{ __html: props.instruction.description }}
        />
      </div>
    </div>
  );
};
