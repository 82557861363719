import { useEffect, useState } from "react";
import { ITest } from "../types";
import { useTestStore } from "../model";
import { useTestHook } from "../hooks/hook";
import {
  AudioPlayer,
  CustomTextarea,
  useAppStore,
  useTextAreaHook,
} from "@shared";
import { Button } from "@nextui-org/react";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { BiSolidDislike, BiSolidLike } from "react-icons/bi";
import { useParams } from "react-router-dom";

type Props = {
  test: ITest;
  index: number;
};

export const TestItem = (props: Props) => {
  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook();

  const {
    text: initialText,
    setText: setInitialText,
    determineErrors: determineInitialTextErrors,
    error: initialTextError,
  } = useTextAreaHook();
  const { setSelectTestId } = useTestStore();

  const { setError } = useAppStore();

  const { page } = useParams();

  const { editTest } = useTestHook();

  const isAuditor = props.test.like || props.test.dislike;

  useEffect(() => {
    setText(props.test.text);
    setInitialText(props.test.initialText);
  }, [props.test]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      (event.key === "Enter" || event.key === "NumpadEnter") &&
      event.shiftKey
    ) {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    const err2 = determineInitialTextErrors();
    if (err2) return setError(err2);
    const err = determineErrors();
    if (err) return setError(err);

    if (text === props.test.text && initialText === props.test.initialText)
      return;
    editTest(
      props.test.id,
      shortenSpaces(text),
      shortenSpaces(initialText),
      props.test.like,
      props.test.dislike
    );
  };

  const handleLike = () =>
    props.test.dislike &&
    editTest(props.test.id, text, initialText, true, false);
  const handleDislike = () =>
    props.test.like && editTest(props.test.id, text, initialText, false, true);
  return (
    <div className="flex items-center py-2 gap-4">
      <span>{props.index + 1 + (Number(page) - 1) * 10}.</span>
      <div className="flex-1 gap-2 flex h-[100px]">
        {!isAuditor && (
          <CustomTextarea
            onKeyDown={handleKeyDown}
            className="!h-[100px]"
            error={initialTextError}
            value={initialText}
            setValue={setInitialText}
          />
        )}
        <CustomTextarea
          onKeyDown={handleKeyDown}
          className="!h-[100px]"
          error={error}
          value={text}
          setValue={setText}
        />
      </div>
      <AudioPlayer src={props.test.audio} speed />
      {isAuditor && (
        <div className="flex gap-3">
          <Button
            size="sm"
            isIconOnly
            variant="light"
            className="rounded-full"
            onClick={handleDislike}
            color={props.test.dislike ? "danger" : "default"}
          >
            <BiSolidDislike className="text-xl" />
          </Button>
          <Button
            size="sm"
            isIconOnly
            variant="light"
            className="rounded-full"
            color={props.test.like ? "danger" : "default"}
            onClick={handleLike}
          >
            <BiSolidLike className="text-xl" />
          </Button>
        </div>
      )}

      <Button
        onClick={handleUpdate}
        isIconOnly
        className="rounded-full"
        variant="light"
        size="sm"
      >
        <RiEdit2Fill className="text-xl" />
      </Button>
      <Button
        onClick={() => setSelectTestId(-1 * props.test.id)}
        isIconOnly
        className="rounded-full"
        variant="light"
        size="sm"
      >
        <MdDelete className="text-xl" color="red" />
      </Button>
    </div>
  );
};
