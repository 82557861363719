import { IResult, ITranscript } from "@entities";
import { IRate, IStatistics, IUser } from "@shared";
import { z } from "zod";

export interface IScore {
  id?: number;
  checking?: number;
  test?: number;
  reset: number;
}

export interface IUserScore extends IUser {
  score: IScore;
  statistics: IStatistics;
  transcripts?: ITranscript[];
  likes?: IRate[];
  dislikes?: IRate[];
  results: IResult[];
}

export interface IUsersStore {
  users: IUserScore[];
  setUsers: (users: IUserScore[]) => void;
  addUser: (user: IUserScore) => void;
  updateUser: (user: IUserScore, reset?: boolean) => void;
  updatePaid: (userId: number, paid: number) => void;
  deleteUser: (id: number) => void;
  getWriters: () => IUserScore[];
  getAuditors: () => IUserScore[];

  selectedUserId: number | null;
  setSelectedUserId: (id: number | null) => void;

  resetId: number | null;
  setResetId: (id: number | null) => void;

  userModalId: number | null;
  setUserModalId: (userId: number | null) => void;
}

export const userSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
  role: z.string().min(1, { message: "Role is required" }),
  phone: z.string().min(9, { message: "Role is required" }),
  password: z
    .string()
    .min(6, { message: "Password must be at least 6 characters" }),
});

export type UserCreationFormInputs = z.infer<typeof userSchema>;

export interface IUserData<T> {
  results: T[];
  count: 0;
  limit?: number;
}
