import { useEffect, useState } from "react";
import { useTestStore } from "../model";
import { TestItem } from "./testItem";
import { useTestHook } from "../hooks/hook";
import { useSearchParams } from "react-router-dom";
import { ListLoader } from "@shared";

export const TestList = () => {
  const { tests } = useTestStore();

  const [loading, setLoading] = useState(true);

  const { getAllTests, determineRole, orderValue, page } = useTestHook();

  useEffect(() => {
    (async function () {
      await getAllTests(determineRole, Number(page) - 1, orderValue);
      setLoading(false);
    })();

    return () => {
      setLoading(true);
    };
  }, [determineRole, orderValue, page]);

  return (
    <div className="h-[calc(100vh-164px)] relative overflow-y-scroll px-6 pt-2">
      <ListLoader loading={loading} height="h-[100px]" />

      {tests.map((test, index) => (
        <TestItem test={test} index={index} key={test.id} />
      ))}
    </div>
  );
};
