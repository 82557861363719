import { Skeleton } from "@nextui-org/react";
import { useEffect, useState } from "react";

type Props = {
  loading: boolean;
  height?: string;
};

export const ListLoader = (props: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.loading) setShow(true);
    else {
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [props.loading]);

  if (show || props.loading)
    return (
      <div
        className={`absolute top-2 left-0 w-full duration-1000 h-full px-4 z-10 transition-all ${
          props.loading ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex flex-col">
          {Array(10)
            .fill(null)
            .map((_, i) => (
              <Skeleton key={i} className=" mx-[36px] my-2 rounded-[14px]">
                <div
                  className={`${
                    props.height || "h-[56px] "
                  }  rounded-lg bg-default-300`}
                ></div>
              </Skeleton>
            ))}
        </div>
      </div>
    );
};
