import { useScrollHook } from "4-features/users/hooks/scroll.hook";
import {
  ITranscript,
  IUserData,
  useDatasetStore,
  useUsersHook,
  useUsersStore,
} from "@entities";
import {
  AudioPlayer,
  DateFormat,
  InfiniteSrollLoader,
  IRate,
  Transcription,
} from "@shared";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";

export interface IRateWithTranscript extends IRate {
  transcript: ITranscript;
  like?: boolean;
  dislike?: boolean;
}

type props = {
  rates: IRateWithTranscript[];
  count: number;
  limit: number;
  userId: number;
  wrapper: HTMLDivElement;
  setRates: Dispatch<SetStateAction<IUserData<IRateWithTranscript>>>;
  url: string;
};

export const UserRates = (props: props) => {
  const { setUserModalId, userModalId } = useUsersStore();
  const { setDatasetModalId } = useDatasetStore();

  const handleSetUserModalId = (id: number) => () => {
    setUserModalId(null);
    setUserModalId(id);
  };
  const handleSetDatasetModalId = (id: number) => () => {
    setUserModalId(null);
    setDatasetModalId(id);
  };

  const [loading, setLoading] = useState(false);
  const { getUserData } = useUsersHook();
  const fetchMoreData = async () => {
    if (props.rates.length === props.count || loading) return;
    setLoading(true);

    const rates = await getUserData<IUserData<IRateWithTranscript>>(
      props.userId,
      props.rates.length,
      props.limit,
      props.url
    );
    setLoading(false);

    props.setRates((prev) => ({
      ...prev,
      results: [...prev.results, ...rates.results],
    }));
  };

  useScrollHook(props.wrapper, fetchMoreData);

  return (
    <Fragment>
      {props.rates.map((rate) => {
        if (rate.transcript && rate.transcript.dataset)
          return (
            <div
              className="flex gap-2 py-2 border-b-[1px] border-neutral-600"
              key={rate.id}
            >
              <div className="!w-1/2">
                <h3
                  className="cursor-pointer pb-3 px-2"
                  onClick={handleSetDatasetModalId(rate.transcript.datasetId)}
                >
                  Dataset: {rate.transcript.datasetId}
                </h3>
                <Transcription
                  className="!bg-black "
                  text={rate.transcript.body}
                />
              </div>
              <div className="w-1/2">
                <AudioPlayer src={rate.transcript.dataset.audio} />
                <div className="pt-4 px-2">
                  Rate amount: {rate.transcript.rateAmount}
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div>Writer: </div>
                  <div
                    onClick={handleSetUserModalId(rate.transcript.userId)}
                    className="flex cursor-pointer gap-2 italic text-blue-300"
                  >
                    {rate.transcript.user.username}
                  </div>
                </div>
                <div className="pt-4 flex gap-3 px-2">
                  <div
                    className={`${
                      rate.userId === userModalId && "text-danger-500"
                    }`}
                  >
                    Liked:{" "}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {rate.transcript.likes.map((like) => (
                      <div
                        onClick={handleSetUserModalId(like.user.id)}
                        className={`italic cursor-pointer ${
                          like.user.id === userModalId
                            ? "text-danger-500"
                            : "text-blue-300"
                        }`}
                        key={like.id}
                      >
                        {like.user.username},
                      </div>
                    ))}
                  </div>
                </div>

                <div className="pt-4 flex gap-3 px-2">
                  <div
                    className={`${
                      rate.userId === userModalId && "text-danger-500"
                    }`}
                  >
                    Disliked:{" "}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {rate.transcript.dislikes.map((dislike) => (
                      <div
                        onClick={handleSetUserModalId(dislike.user.id)}
                        className={`cursor-pointer italic ${
                          dislike.user.id === userModalId
                            ? "text-danger-500"
                            : "text-blue-300"
                        }`}
                        key={dislike.id}
                      >
                        {dislike.user.username},
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pt-4 flex gap-3 items-end px-2">
                  <strong>Dataset status:</strong>
                  <span className="underline italic">
                    {rate.transcript.dataset.status}
                  </span>
                </div>
                <div className="pt-4 flex gap-3 items-end px-2">
                  <strong>Transcript status:</strong>
                  <span className="underline italic">
                    {rate.transcript.status}
                  </span>
                </div>
                <div className="pt-4 flex gap-3 items-end px-2">
                  <strong>Created:</strong>
                  <DateFormat date={String(rate.createdAt)} />
                </div>
              </div>
            </div>
          );
      })}
      <InfiniteSrollLoader loading={loading} />
    </Fragment>
  );
};
