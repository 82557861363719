import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useWordsStore } from "../store";
import { useWordsHook } from "../hook";
import { modalMode } from "../types";

export const WordDeleteModal = () => {
  const { selectedIds, wordsModal, setSelectedIds, setWordsModal } =
    useWordsStore();

  const { truncateWords, deleteSelectedWords } = useWordsHook();
  const handleRemove = () => {
    if (wordsModal === modalMode.clear) truncateWords();
    if (wordsModal === modalMode.delete) deleteSelectedWords(selectedIds);
    onClose();
  };
  const onClose = () => {
    setSelectedIds([]);
    setWordsModal(null);
  };
  const open =
    wordsModal === modalMode.clear || wordsModal === modalMode.delete;
  return (
    <Modal
      backdrop={"blur"}
      isOpen={open}
      onClose={onClose}
      className="dark"
      isDismissable={false}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              Are you sure?
            </ModalHeader>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={handleRemove}>
                Yes
              </Button>
              <Button color="default" onPress={onClose}>
                No
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
