import { Pagination } from "@nextui-org/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type Props = {
  total: number;
};

export const CustomPagination = (props: Props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { page } = useParams();
  const handleChange = (p: number) => {
    navigate(pathname.slice(0, pathname.lastIndexOf("/")) + "/" + p + search);
  };
  if (!isNaN(Number(page)) && props.total > 0)
    return (
      <div className="flex py-2 w-full justify-center">
        <Pagination
          loop
          onChange={handleChange}
          showControls
          variant="light"
          color="default"
          total={props.total}
          page={Number(page)}
          initialPage={Number(page)}
        />
      </div>
    );
};
