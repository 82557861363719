import {
  IRateWithTranscript,
  IResult,
  ITranscript,
  IUserData,
  IUserScore,
  useUsersHook,
} from "@entities";
import { useEffect, useRef, useState } from "react";
import { loadingValues, testResultsItemsHeight } from "../constants";
import { IRate } from "@shared";

export const obj = {
  results: [],
  count: 0,
  limit: 0,
};

export const useGetDataHook = (userId: number, wrapperHeight: number) => {
  const fetched = useRef(false);

  const [user, setUser] = useState<IUserScore | null>(null);

  const [loading, setLoading] = useState(loadingValues.false);
  const [testResults, setTestResults] = useState<IUserData<IResult>>(
    obj as IUserData<IResult>
  );
  const [transcripts, setTranscripts] = useState<IUserData<ITranscript>>(
    obj as IUserData<ITranscript>
  );
  const [likes, setLikes] = useState<IUserData<IRateWithTranscript>>(
    obj as IUserData<IRateWithTranscript>
  );

  const [dislikes, setDislikes] = useState<IUserData<IRateWithTranscript>>(
    obj as IUserData<IRateWithTranscript>
  );

  const { getUserHistory, getUserData } = useUsersHook();

  const offset = 0;

  const limitTestResults =
    Math.floor(wrapperHeight / testResultsItemsHeight) + 1;
  const limitTranscripts =
    Math.floor(wrapperHeight / testResultsItemsHeight) + 1;
  const limitRatedTranscripts =
    Math.floor(wrapperHeight / testResultsItemsHeight) + 1;

  useEffect(() => {
    if (!userId) return;
    if (!wrapperHeight) return;
    if (fetched.current) return;

    setLoading(loadingValues.initialLoading);

    (async function () {
      const userScore = await getUserHistory(userId);
      const tests = await getUserData<IUserData<IResult>>(
        userId,
        offset,
        limitTestResults,
        "testResults"
      );

      const transcripts = await getUserData<IUserData<ITranscript>>(
        userId,
        offset,
        limitTranscripts,
        "transcripts"
      );

      const likes = await getUserData<IUserData<IRateWithTranscript>>(
        userId,
        offset,
        limitRatedTranscripts,
        "likes"
      );
      const dislikes = await getUserData<IUserData<IRateWithTranscript>>(
        userId,
        offset,
        limitRatedTranscripts,
        "dislikes"
      );

      fetched.current = true;

      setUser(userScore);
      setTestResults({ ...tests, limit: limitTestResults });
      setTranscripts({ ...transcripts, limit: limitTestResults });
      setLikes({ ...likes, limit: limitTestResults });
      setDislikes({ ...dislikes, limit: limitTestResults });

      setLoading(loadingValues.false);
    })();
  }, [userId, wrapperHeight]);

  return {
    user,
    loading,
    testResults,
    setTestResults,
    transcripts,
    setTranscripts,
    dislikes,
    setDislikes,
    likes,
    setLikes,
    setUser,
    fetched,
  };
};
