import {
  IUser,
  keyForAuditorData,
  keyForWriterData,
  localStorageKey,
  METHODS,
  role,
  routes,
  useAppStore,
  useAxios,
  useProcessHook,
} from "@shared";
import { useCallback, useState } from "react";
import { LoginFormInputs } from "../types";
import { useNavigate } from "react-router-dom";

export const useAuthHook = () => {
  const { fetchData } = useAxios();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const { setUser } = useAppStore();
  const { getTaskInfo } = useProcessHook();
  const navigate = useNavigate();

  const login = useCallback(async (data: LoginFormInputs) => {
    setLoading(true);
    const response = await fetchData<IUser>(
      "/auth/login",
      METHODS.POST,
      data,
      false
    );
    setLoading(false);

    if (!response.data) return setMessage(response.message);

    setUser(response.data);

    navigate(routes[response.data.role].key);

    response.data.taskId && getTaskInfo(response.data.taskId);
  }, []);

  const logout = useCallback(async () => {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(keyForAuditorData);
    localStorage.removeItem(keyForWriterData);
    await fetchData("/auth/logout");

    setUser(null);

    navigate(routes.login.key);
  }, []);

  return {
    login,
    logout,
    loading,
    message,
  };
};
