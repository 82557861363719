import { IResult, IUserData, useUsersHook } from "@entities";
import { UserRateResultItem } from "./userTestResultItem";
import { Spinner } from "@nextui-org/react";
import { InfiniteSrollLoader } from "@shared";
import { useScrollHook } from "4-features/users/hooks/scroll.hook";
import { Dispatch, SetStateAction, useState } from "react";
type props = {
  results: IResult[];
  count: number;
  limit: number;
  userId: number;
  wrapper: HTMLDivElement;
  setTestResults: Dispatch<SetStateAction<IUserData<IResult>>>;
};

export const UserTestResults = (props: props) => {
  const [loading, setLoading] = useState(false);
  const { getUserData } = useUsersHook();
  const fetchMoreData = async () => {
    if (props.results.length === props.count || loading) return;
    setLoading(true);

    const tests = await getUserData<IUserData<IResult>>(
      props.userId,
      props.results.length,
      props.limit,
      "testResults"
    );
    setLoading(false);

    props.setTestResults((prev) => ({
      ...prev,
      results: [...prev.results, ...tests.results],
    }));
  };

  useScrollHook(props.wrapper, fetchMoreData);
  return (
    <div className="flex flex-col gap-6">
      {props.results.map((result) => (
        <UserRateResultItem result={result} key={result.id} />
      ))}
      <InfiniteSrollLoader loading={loading} />
    </div>
  );
};
