import { create } from "zustand";
import { ITestStore } from "../types";
import { role } from "@shared";

export const useTestStore = create<ITestStore>((set) => ({
  tests: [],
  setTests: (tests) => set({ tests }),
  updateTest: (test) =>
    set((state) => ({
      tests: state.tests.map((t) => (t.id === test.id ? { ...t, ...test } : t)),
    })),

  count: 0,
  quantityTests: {
    [role.auditor]: 0,
    [role.writer]: 0,
  },
  setQuantityTests: (quantityTests, count) => set({ quantityTests, count }),

  selectTestId: null,
  setSelectTestId: (testId) => set({ selectTestId: testId }),

  clear: false,
  setClear: (clear) => set({ clear }),
}));
