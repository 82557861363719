import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useDatasetStore } from "../model";
import {
  CustomTextarea,
  ErrorText,
  useAppStore,
  useTextAreaHook,
} from "@shared";
import { useRef, useState } from "react";
import { MdAudioFile } from "react-icons/md";
import { MdOutlineClear } from "react-icons/md";
import { useDatasetHook } from "../hooks/hook";

export const DatasetCreate = () => {
  const { selectedDatasetId, setSelectedDatasetId } = useDatasetStore();
  const { setError } = useAppStore();
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<null | HTMLInputElement>(null);
  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook();
  const [path, setPath] = useState("");

  const { createDataset } = useDatasetHook();
  const handleClear = () => {
    fileRef.current.value = "";
    setFile(null);
  };

  const handleSubmit = async () => {
    if (!file) return setError("Please upload audio file");

    const formdata = new FormData();

    if (determineErrors()) return;

    formdata.append("file", file);
    formdata.append("text", shortenSpaces(text));
    formdata.append("path", path);
    onClose();
    await createDataset(formdata);
  };

  const onClose = () => {
    handleClear();
    setText("");
    setSelectedDatasetId(null);
  };
  return (
    <Modal
      backdrop={"blur"}
      isOpen={selectedDatasetId === 0}
      onClose={onClose}
      className="dark"
      isDismissable={false}
      size="lg"
      placement="top-center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              Create dataset item
            </ModalHeader>
            <ModalBody>
              <ErrorText error={error} />
              <CustomTextarea
                className="!bg-black"
                error={error}
                value={text}
                setValue={setText}
              />
              <Input
                className="text-white"
                placeholder="path"
                value={path}
                onChange={(e) => setPath(e.target.value)}
                classNames={{
                  inputWrapper: ["!bg-black"],
                }}
              />
              <div className="relative">
                <Input
                  id="audio-file"
                  type="file"
                  ref={fileRef}
                  onChange={(e) => setFile(e.target.files[0])}
                  className="absolute hidden"
                  accept=".wav,audio/wav,audio/wave,audio/x-wav"
                />
                <Button
                  className="p-0 w-full"
                  size="sm"
                  color={file ? "success" : "default"}
                >
                  <label
                    className="cursor-pointer gap-2 px-2 w-full h-full flex items-center"
                    htmlFor="audio-file"
                  >
                    <MdAudioFile className="text-xl" />{" "}
                    <span>{file ? "File uploaded" : "Upload wav file"}</span>
                  </label>
                  {file && (
                    <MdOutlineClear
                      onClick={handleClear}
                      className="text-8xl px-2"
                    />
                  )}
                </Button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="flat" onPress={onClose}>
                Close
              </Button>
              <Button color="default" onPress={handleSubmit}>
                Create
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
