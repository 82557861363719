import {
  CustomTextarea,
  DateFormat,
  ErrorText,
  useTextAreaHook,
  waitTime,
} from "@shared";
import { ITranscript, transcriptStatus } from "../types";
import { useEffect } from "react";
import { Button } from "@nextui-org/react";
import { useDatasetHook } from "../hooks/hook";
import { useDatasetStore } from "../model";
import { useUsersStore } from "@entities";

type props = {
  transcript: ITranscript;
};

export const DatasetHistoryItem = ({ transcript }: props) => {
  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook();
  const { confirmDataset, editDataset } = useDatasetHook();
  const { datasetModalId, setDatasetModalId, undo, setUndo } =
    useDatasetStore();

  const handleConfirm = (rejected: boolean) => () => {
    if (determineErrors()) return;
    setDatasetModalId(null);

    const timeout = setTimeout(() => {
      confirmDataset(datasetModalId, rejected, text);
    }, waitTime);

    setUndo({
      ...undo,
      [datasetModalId]: {
        timeout,
        endTime: Date.now() + waitTime,
        rejected,
        text: shortenSpaces(text),
      },
    });
  };

  const { setUserModalId } = useUsersStore();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      (event.key === "Enter" || event.key === "NumpadEnter") &&
      event.shiftKey
    ) {
      event.preventDefault();
      if (transcript.status === transcriptStatus.ready) {
        handleUpdate();
      }
    }
  };

  //update
  const handleUpdate = () => {
    if (determineErrors()) return;
    if (text === transcript.body) return;

    editDataset(transcript.datasetId, shortenSpaces(text), true);
  };

  useEffect(() => {
    setText(transcript.body);
  }, [transcript.body]);
  const handleSetUserModalId = (id: number) => () => {
    setDatasetModalId(null);
    setUserModalId(id);
  };
  return (
    <div className="flex gap-4">
      <div className="w-1/2">
        <ErrorText error={error} />
        <CustomTextarea
          value={text}
          error={error}
          setValue={setText}
          disabled={
            transcript.status !== transcriptStatus.conflict &&
            transcript.status !== transcriptStatus.ready
          }
          onKeyDown={handleKeyDown}
          className="!bg-black  !h-[250px] p-4 text-xl"
        />
        {transcript.status === transcriptStatus.ready && (
          <div className="flex gap-2 justify-end">
            <Button onClick={handleUpdate} size="sm">
              Update
            </Button>
          </div>
        )}
        {transcript.status === transcriptStatus.conflict && (
          <div className="flex gap-2">
            <Button onClick={handleConfirm(false)} size="sm" color="success">
              Accept
            </Button>
            <Button
              onClick={handleConfirm(true)}
              isDisabled={text !== transcript.body}
              size="sm"
              color="danger"
            >
              Reject
            </Button>
          </div>
        )}
      </div>

      <div className="w-1/2 p-4">
        <div className="flex justify-between">
          <div className="flex gap-3">
            <span>Writer:</span>
            <span
              onClick={handleSetUserModalId(transcript.userId)}
              className={`text-blue-300 italic cursor-pointer ${
                transcript.user.deleted && "line-through"
              }`}
            >
              {transcript.user.username}
            </span>
          </div>
          <div className="flex gap-3">
            <span className="text-green-400">{transcript.status}</span>
            <DateFormat date={transcript.createdAt} />
          </div>
        </div>
        <div className="pt-4">Rate amount: {transcript.rateAmount}</div>
        <div className="pt-4 flex gap-3">
          <div>Liked: </div>
          <div className="flex gap-2">
            {transcript.likes.map((rate) => (
              <div key={rate.id} className="flex gap-2">
                <span
                  onClick={handleSetUserModalId(rate.userId)}
                  className={`text-blue-300 italic cursor-pointer ${
                    rate.user.deleted && "line-through"
                  }`}
                >
                  {rate.user.username}
                </span>
                ,
              </div>
            ))}
          </div>
        </div>
        <div className="pt-4 flex gap-3">
          <div>Disliked: </div>
          <div className="flex gap-2">
            {transcript.dislikes.map((rate) => (
              <div key={rate.id} className="flex gap-2">
                <span
                  onClick={handleSetUserModalId(rate.userId)}
                  className={`text-blue-300 italic cursor-pointer ${
                    rate.user.deleted && "line-through"
                  }`}
                >
                  {rate.user.username}
                </span>
                ,
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
