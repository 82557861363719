import { useLocation } from "react-router-dom";

export const usePathnameHook = () => {
  const { pathname } = useLocation();

  const pathes = pathname.split("/");

  const getBasePathname = () => "/" + pathes[1] + "/" + pathes[2];

  return { getBasePathname, pathname };
};
