import { Button, Input } from "@nextui-org/react";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { HiOutlineArrowLongUp } from "react-icons/hi2";
import { HiOutlineArrowLongDown } from "react-icons/hi2";
import { GiBurningSkull } from "react-icons/gi";
import { IoAdd } from "react-icons/io5";
import { MdFolderZip } from "react-icons/md";
import { useDatasetHook } from "../hooks/hook";
import { useDatasetStore } from "../model";
import {
  orderValues,
  role,
  routes,
  sortOrderKey,
  sortTypeKey,
  useAppStore,
} from "@shared";
import { useLocation, useSearchParams } from "react-router-dom";
import { sortKeysType, sortTypeKeys } from "../types";

export const DatasetHeader = () => {
  const { toastId, uploadProgress } = useAppStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const { pathname } = useLocation();

  const disabled = Boolean(toastId) || uploadProgress !== null;
  const { uploadDataset } = useDatasetHook();
  const { setSelectedDatasetId, setClearDataset } = useDatasetStore();
  const sortOrderValue = searchParams.get(sortOrderKey) || orderValues.ASC;
  const sortTypeValue = searchParams.get(sortTypeKey) || sortTypeKeys.id;

  const handleChangeSort = (sortTypeKeyValue: sortKeysType) => () => {
    searchParams.set(
      sortOrderKey,
      sortOrderValue === orderValues.ASC ? orderValues.DESC : orderValues.ASC
    );
    searchParams.set(sortTypeKey, sortTypeKeyValue);
    setSearchParams(searchParams);
  };
  const { exportDataset } = useDatasetHook();

  return (
    <div className="flex justify-between w-full px-6 py-2 pt-4">
      <div className="flex gap-4">
        <div className="relative">
          <Input
            onChange={uploadDataset}
            id="file"
            type="file"
            className="absolute hidden"
            accept=".zip,application/zip,application/x-zip-compressed,multipart/x-zip"
          />
          <Button isDisabled={disabled} className="p-0" size="sm">
            <label
              className="cursor-pointer gap-2 px-2 h-full flex items-center"
              htmlFor="file"
            >
              {<MdOutlineDriveFolderUpload className="text-xl" />}
              <span>IMPORT DATASET</span>
            </label>
          </Button>
        </div>

        <Button
          onClick={() => setSelectedDatasetId(0)}
          className="flex gap-2"
          size="sm"
          isDisabled={disabled}
        >
          <IoAdd className="text-xl" />
          <span>CREATE</span>
        </Button>
        <Button
          size="sm"
          onClick={handleChangeSort(sortTypeKeys.id)}
          className="flex gap-2"
          color={sortTypeValue === sortTypeKeys.id ? "success" : "default"}
        >
          {sortOrderValue === orderValues.ASC ? (
            <HiOutlineArrowLongUp className="text-md" />
          ) : (
            <HiOutlineArrowLongDown className="text-md" />
          )}
          <span>SORT BY ID</span>
        </Button>
        {!pathname.includes(routes[role.admin].dataset.new.paginationPath) &&
          !pathname.includes(
            routes[role.admin].dataset.drafted.paginationPath
          ) && (
            <Button
              size="sm"
              onClick={handleChangeSort(sortTypeKeys.rate)}
              color={
                sortTypeValue === sortTypeKeys.rate ? "success" : "default"
              }
              className="flex gap-2"
            >
              {sortOrderValue === orderValues.ASC ? (
                <HiOutlineArrowLongUp className="text-md" />
              ) : (
                <HiOutlineArrowLongDown className="text-md" />
              )}
              <span>SORT BY RATE</span>
            </Button>
          )}

        <Button
          onClick={() => setClearDataset(true)}
          variant="solid"
          className="bg-red-600"
          size="sm"
        >
          <GiBurningSkull className="text-xl" />
          <span>CLEAR</span>
        </Button>
      </div>
      <Button onClick={exportDataset} size="sm">
        <MdFolderZip className="text-xl" />
        <span>EXPORT DATASET</span>
      </Button>
    </div>
  );
};
