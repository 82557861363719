import { role, routes } from "@shared";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Login } from "./login";
import { Dataset } from "./admin/dataset";
import { Settings } from "./admin/settings";
import { Test } from "./admin/test";
import { Users } from "./admin/users";
import { Admin } from "./admin";
import {
  DatasetPage,
  TestPage,
  UsersPage,
  UsersWithScores,
  UserWithStatistics,
} from "@features";
import { datasetStatus } from "@entities";
import { Writer } from "./writer";
import { Auditor } from "./auditor";
import { Instructions } from "./admin/instructions";
import { Words } from "./admin/words";

const router = [
  {
    path: routes.login.key,
    element: <Login />,
  },
  {
    path: routes.admin.key,
    element: <Admin role={role.admin} />,
    children: [
      {
        path: "",
        element: <Navigate to={routes.admin.dataset.path} replace />,
      },
      {
        path: routes.admin.dataset.key,
        element: <Dataset />,
        children: [
          {
            path: "",
            element: <Navigate to={routes.admin.dataset.new.path} />,
          },
          {
            path: routes.admin.dataset.new.key,
            element: <DatasetPage status={datasetStatus.new} />,
          },
          {
            path: routes.admin.dataset.rejected.key,
            element: <DatasetPage status={datasetStatus.rejected} />,
          },
          {
            path: routes.admin.dataset.drafted.key,
            element: <DatasetPage status={datasetStatus.drafted} />,
          },
          {
            path: routes.admin.dataset.reviewing.key,
            element: <DatasetPage status={datasetStatus.reviewing} />,
          },
          {
            path: routes.admin.dataset.processing.key,
            element: <DatasetPage status={datasetStatus.processing} />,
          },
          {
            path: routes.admin.dataset.conflict.key,
            element: <DatasetPage status={datasetStatus.conflict} />,
          },
          {
            path: routes.admin.dataset.ready.key,
            element: <DatasetPage status={datasetStatus.ready} />,
          },
        ],
      },
      {
        path: routes.admin.users.key,
        element: <Users />,
        children: [
          {
            path: "",
            element: <Navigate to={routes.admin.users.scores.path} />,
          },
          {
            path: routes.admin.users.userList.key,
            element: <UsersPage />,
          },

          {
            path: routes.admin.users.scores.key,
            element: <UsersWithScores />,
          },

          {
            path: routes.admin.users.statistics.key,
            element: <UserWithStatistics />,
          },
        ],
      },

      {
        path: routes.admin.test.key,
        element: <Test />,
        children: [
          {
            path: "",
            element: <Navigate to={routes.admin.test.writer.path} />,
          },
          {
            path: routes.admin.test.writer.key,
            element: <TestPage />,
          },
          {
            path: routes.admin.test.auditor.key,
            element: <TestPage />,
          },
        ],
      },
      {
        path: routes.admin.settings.key,
        element: <Settings />,
      },
      {
        path: routes.admin.instructions.key,
        element: <Instructions />,
      },
      {
        path: routes.admin.words.key,
        element: <Words />,
      },
    ],
  },

  {
    path: routes.writer.key,
    element: <Writer role={role.writer} />,
  },
  {
    path: routes.auditor.key,
    element: <Auditor role={role.auditor} />,
  },

  {
    path: "*",
    element: <Navigate to={routes.login.key} replace />,
  },
];

export const Pages = () => {
  return <RouterProvider router={createBrowserRouter(router)} />;
};
