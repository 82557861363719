import {
  DatasetClear,
  DatasetCreate,
  DatasetDelete,
  DeleteUserModal,
  InstructionDelete,
  TestCreate,
  TestDelete,
  WordCreateModal,
  WordDeleteModal,
} from "@entities";
import { DatasetItemModal, UserItemModal } from "@features";
import { Fragment } from "react/jsx-runtime";

export const Modals = () => {
  return (
    <Fragment>
      <DeleteUserModal />
      <DatasetCreate />
      <DatasetDelete />
      <DatasetClear />
      <TestCreate />
      <TestDelete />
      <InstructionDelete />
      <DatasetItemModal />
      <UserItemModal />
      <WordDeleteModal />
      <WordCreateModal />
    </Fragment>
  );
};
