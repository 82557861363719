import { role } from "@shared";

export type roleType = role.admin | role.auditor | role.writer;

export type PageProps = {
  role: roleType;
};

export interface ITask {
  id: number;
  active: boolean;
  title: string;
  data: string;
}

export interface IRate {
  id: number;
  createdAt: string;
  transcriptId: number;
  userId: number;
  user?: IUser;
}

export interface IUser {
  id: number;
  username: string;
  role: roleType;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  taskId?: string;
  deleted?: boolean;
}

export interface IStatistics {
  id: number;
  accepted: number;
  rejected: number;
  paid: number;
}

export enum orderValues {
  ASC = "ASC",
  DESC = "DESC",
}

export const CLEAR = "CLEAR DATABASE";

export interface IAppStore {
  user: null | IUser;
  setUser: (user: null | IUser) => void;

  full: boolean;
  loading: boolean;
  setLoading: (loading: boolean, full?: boolean) => void;

  error: string | null;
  setError: (error: string | null) => void;

  info: string | null;
  setInfo: (info: string | null) => void;

  toastId: null | string;
  setToastId: (toastId: string | null) => void;

  uploadProgress: number | null;
  setUploadProgress: (uploadProgress: number | null) => void;
}
