import { Spinner } from "@nextui-org/react";

type props = { loading: boolean };

export const InfiniteSrollLoader = (props: props) => {
  if (props.loading)
    return (
      <div className="w-full flex justify-center">
        <Spinner color="white" />
      </div>
    );
};
