import { CreateUser, UserList, useUsersStore } from "@entities";

export const UsersPage = () => {
  const { getAuditors, getWriters } = useUsersStore();
  const audiotors = getAuditors();
  const writers = getWriters();

  return (
    <div className="flex w-full">
      <div className="flex-[2] px-4 pt-4 overflow-y-scroll mb-4">
        <div className="pt-3">
          <h3 className="text-xl">Writers - {writers.length}</h3>
          <UserList users={writers} />
        </div>
        <div className="pt-3">
          <h3 className="text-xl">Auditors - {audiotors.length}</h3>
          <UserList users={audiotors} />
        </div>
      </div>
      <div className="flex-1">
        <CreateUser />
      </div>
    </div>
  );
};
