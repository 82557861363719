import { AdminDatasetDuration, AdminNavigation } from "@entities";
import { useAdminHook } from "../hooks";

export const AdminHeader = () => {
  useAdminHook();
  return (
    <div className="flex justify-between w-full pr-6">
      <AdminDatasetDuration />
      <AdminNavigation />
    </div>
  );
};
