import { Skeleton } from "@nextui-org/react";
import { useAppStore } from "6-shared/store";

export const DatasetUploadProgress = () => {
  const { uploadProgress } = useAppStore();

  return (
    <div className="w-full ">
      <h3 className="px-1 pb-1">Uploading...</h3>
      <div
        className="relative transition-all duration-300"
        style={{ width: `${uploadProgress}%` }}
      >
        <div className="absolute h-8 w-full flex items-center transition-all duration-300 justify-center top-0 left-0 z-10">
          {uploadProgress >= 10 ? `${uploadProgress}%` : ""}
        </div>
        <Skeleton className="w-full rounded-lg">
          <div className="h-8 rounded-lg bg-default-200"></div>
        </Skeleton>
      </div>
    </div>
  );
};
