import { IUser } from "@shared";
import { UserItem } from "./userItem";

type Props = {
  users: IUser[];
};

export const UserList = (props: Props) => {
  return (
    <div className="flex flex-col gap-3 pt-4">
      {props.users
        .sort((a, b) => a.id - b.id)
        .map((user) => (
          <UserItem user={user} key={user.id} />
        ))}
    </div>
  );
};
