import { create } from "zustand";
import { IUserScore, IUsersStore } from "../types";
import { IUser, role } from "@shared";

export const useUsersStore = create<IUsersStore>((set, get) => ({
  users: [],
  setUsers: (users: IUserScore[]) => set({ users }),
  addUser(user) {
    set((state) => ({
      users: [...state.users, user],
    }));
  },
  updateUser(user, reset = false) {
    set((state) => ({
      users: state.users.map((u) =>
        u.id === user.id ? (reset ? { ...u } : { ...u, ...user }) : u
      ),
    }));
  },
  updatePaid(userId, paid) {
    set((state) => {
      return {
        users: state.users.map((u) =>
          u.id === userId ? { ...u, statistics: { ...u.statistics, paid } } : u
        ),
      };
    });
  },
  deleteUser(id) {
    set((state) => ({
      users: state.users.filter((u) => u.id !== id),
    }));
  },
  getAuditors() {
    const { users } = get();
    return users.filter((u) => u.role === role.auditor);
  },
  getWriters() {
    const { users } = get();
    return users.filter((u) => u.role === role.writer);
  },

  selectedUserId: null,
  setSelectedUserId: (id) => set({ selectedUserId: id }),

  resetId: null,
  setResetId: (id) => set({ resetId: id }),

  userModalId: null,
  setUserModalId: (userId) => set({ userModalId: userId }),
}));
