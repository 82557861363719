import { IUserScore, UserStatisticsTable, useUsersStore } from "@entities";
import { Button } from "@nextui-org/react";
import { exportToExcel, formatTime, role } from "@shared";
import { RiFileExcel2Fill } from "react-icons/ri";
export const UserWithStatistics = () => {
  const { users } = useUsersStore();
  const writers = users.filter((user) => user.role === role.writer);
  const auditors = users.filter((user) => user.role === role.auditor);
  const filter = (user: IUserScore) => ({
    username: user.username,
    phone: user.phone,
    accepted: formatTime(user.statistics.accepted, "hh:mm"),
    rejected: formatTime(user.statistics.rejected, "hh:mm"),
    paid: formatTime(user.statistics.paid * 3600, "hh:mm"),
    unpaid: formatTime(
      user.statistics.accepted - user.statistics.paid * 3600,
      "hh:mm"
    ),
  });
  return (
    <div className="flex p-6 w-full gap-4">
      <div className="flex-1 flex flex-col">
        <div className="flex w-full justify-between">
          <h2 className="pb-4">Writers</h2>
          <Button onClick={exportToExcel(writers.map(filter))} size="sm">
            Export
            <RiFileExcel2Fill className="text-xl" />
          </Button>
        </div>
        <UserStatisticsTable users={writers} />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex w-full justify-between">
          <h2 className="pb-4">Auditors</h2>
          <Button onClick={exportToExcel(auditors.map(filter))} size="sm">
            Export
            <RiFileExcel2Fill className="text-xl" />
          </Button>
        </div>
        <UserStatisticsTable users={auditors} />
      </div>
    </div>
  );
};
