/* eslint-disable react-hooks/exhaustive-deps */
import { InstructionListModal, useInstructionsHook } from "@entities";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const InstructionsModal = () => {
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();

  const { getInstructions } = useInstructionsHook();

  const onClose = () => navigate(pathname + search);

  useEffect(() => {
    getInstructions();
  }, []);

  return (
    <Modal
      size={"full"}
      scrollBehavior="inside"
      isOpen={hash === "#instructions"}
      onClose={onClose}
      classNames={{
        closeButton: "text-danger-500",
      }}
    >
      <ModalContent className="dark text-white">
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Instructions
            </ModalHeader>
            <ModalBody>
              <InstructionListModal />
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
