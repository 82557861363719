import { useUsersHook } from "@entities";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const Users = () => {
  const { getUsers } = useUsersHook();

  useEffect(() => {
    getUsers(false);
  }, []);
  return <Outlet />;
};
