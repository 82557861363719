import { BiSpaceBar } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowDown } from "react-icons/bs";
export const ShortCutKeys = () => {
  return (
    <ul className="text-gray-400 text-sm p-1 mt-2 font-mono font-thin">
      <li className="flex ">
        <span>Shift +</span> <BiSpaceBar className="mx-2 text-xl" />
        <span>: Audioni eshitish</span>
      </li>
      <li className="flex ">
        <span>Ctrl +</span> <span className="mx-2"> Enter</span>
        <span>: Keyingisiga o'tkazish</span>
      </li>
      <li className="flex ">
        <span>Shift +</span> <BsArrowLeft className="mx-2 text-lg" />
        <span>: Audioni orqaga surish</span>
      </li>
      <li className="flex ">
        <span>Shift +</span> <BsArrowRight className="mx-2 text-lg" />
        <span>: Audioni oldinga surish</span>
      </li>
      <li className="flex ">
        <span>Shift +</span> <BsArrowDown className="mx-2 text-lg" />
        <span>: Audioni boshiga o'tkazish</span>
      </li>
      {/* <li>Ctrl + Enter: Keyingisiga o'tkazish</li>
      <li>Shift + ArrowLeft: Audioni orqaga surish</li>
      <li>Shift + ArrowRight: Audioni oldinga surish</li>
      <li>Shift + ArrowDown: Audioni boshiga o'tkazish</li> */}
    </ul>
  );
};
