import { formatTime } from "6-shared/utils";
import React from "react";

interface TimeFormatProps {
  seconds: number;
  format?: string; // Optional format string (e.g., 'hh:mm:ss' or 'mm:ss')
}

export const Duration: React.FC<TimeFormatProps> = ({ seconds, format }) => {
  return <span>{formatTime(seconds, format)}</span>;
};
