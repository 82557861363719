import { Skeleton } from "@nextui-org/react";
import { useEffect, useState } from "react";

type Props = {
  loading: boolean;
};

export const WordsLoader = (props: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.loading) setShow(true);
    else {
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [props.loading]);

  if (show || props.loading)
    return (
      <div
        className={`absolute top-2 left-0 w-full duration-1000 h-full px-4 z-10 transition-all ${
          props.loading ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex  flex-wrap">
          {Array(60)
            .fill(null)
            .map((_, i) => (
              <div key={i} className="p-2 w-1/4 h-[48px] pl-[36px]">
                <Skeleton className="rounded-[6px] ">
                  <div className="h-[32px] rounded-lg bg-default-300"></div>
                </Skeleton>
              </div>
            ))}
        </div>
      </div>
    );
};
