import {
  DatasetUploadProgress,
  downloadFile,
  ITask,
  METHODS,
  orderValues,
  role,
  routes,
  sortOrderKey,
  sortTypeKey,
  useAppStore,
  useAxios,
  useProcessHook,
} from "@shared";
import { useCallback, useState } from "react";
import { datasetStatusValueType, IDataset, sortTypeKeys } from "../types";
import { useDatasetStore } from "../model";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDatasetDurationHook } from "@entities";
import { toast } from "sonner";

const development = process.env.NODE_ENV !== "production";

export const useDatasetHook = () => {
  const { fetchData } = useAxios();

  const [searchParams, setSearchParams] = useSearchParams();
  const sortOrderValue = searchParams.get(sortOrderKey) || orderValues.ASC;
  const sortTypeValue = searchParams.get(sortTypeKey) || sortTypeKeys.id;
  const { setError, setInfo, setUploadProgress } = useAppStore();

  const navigate = useNavigate();

  const { page } = useParams();

  const { pathname } = useLocation();

  const { getTaskInfo } = useProcessHook();

  const {
    setDatasets,
    setAmountOfDatasetItems,
    updateDataset,
    setLoading,
    updateTranscript,
  } = useDatasetStore();

  const { getAudioDuration } = useDatasetDurationHook();

  const refreshPage = () => {
    getAudioDuration();
    searchParams.set(sortOrderKey, orderValues.DESC);
    searchParams.set(sortTypeKey, sortTypeKeys.id);

    if (pathname === routes[role.admin].dataset.new.path) {
      setSearchParams(searchParams);
    } else
      navigate(
        routes[role.admin].dataset.new.path + "?" + searchParams.toString()
      );
  };

  const uploadDataset = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      const formdata = new FormData();

      formdata.append("file", file);
      setUploadProgress(0);
      const toastId = toast(<DatasetUploadProgress />, {
        duration: Infinity,
        position: "bottom-right",
      });

      const response = await fetchData<ITask>(
        "/dataset/bulk",
        METHODS.POST,
        formdata,
        false,
        {},
        true,
        setUploadProgress
      );
      event.target.value = "";

      setUploadProgress(100);
      setTimeout(() => {
        setUploadProgress(null);
        toast.dismiss(toastId);
        if (response.data) getTaskInfo(String(response.data.id));
        else setError(response.message);
      }, 500);
    },
    [refreshPage]
  );

  const getDataset = useCallback(
    async (
      offset: number,
      sortByAttribute: string,
      orderKey: string,
      status: datasetStatusValueType
    ) => {
      const response = await fetchData<{ dataset: IDataset[]; count: 0 }>(
        "/dataset/admin",
        METHODS.POST,
        { offset, sortByAttribute, orderKey, status },
        false
      );
      if (response.data) {
        setDatasets(response.data.dataset);
        setAmountOfDatasetItems(response.data.count);
      }
    },
    []
  );

  const createDataset = useCallback(
    async (data: FormData) => {
      const response = await fetchData<IDataset>(
        "/dataset",
        METHODS.POST,
        data
      );
      if (response.data) {
        setInfo("Dataset created");
        return refreshPage();
      }

      setError(response.message);
    },
    [refreshPage]
  );

  const deleteDataset = useCallback(
    async (id: number) => {
      await fetchData("/dataset/" + id, METHODS.DELETE, null, false);
      setInfo("Dataset deleted");
      getAudioDuration();
      getDataset(
        Number(page) - 1,
        sortTypeValue,
        sortOrderValue,
        pathname.split("/")[3] as datasetStatusValueType
      );
    },
    [pathname, page, sortOrderValue, sortTypeValue]
  );

  const editDataset = useCallback(
    async (id: number, text: string, transcript?: boolean) => {
      const response = await fetchData(
        `/dataset/${id}`,
        METHODS.PUT,
        { text },
        false
      );
      if (response.data) {
        if (transcript) updateTranscript(id, text);
        else updateDataset(response.data);
        setInfo("Dataset updated");
      } else setError(response.message);
    },
    []
  );

  const truncateDataset = useCallback(async () => {
    const response = await fetchData("/dataset/clear", METHODS.POST);
    if (response.data) {
      setInfo("Database cleared, You can't find those files anymore");
      refreshPage();
    } else setError(response.message);
  }, [refreshPage]);

  const getDatasetById = useCallback(async (id: number) => {
    const response = await fetchData<IDataset>(
      `/dataset/admin/datasetItem/${id}`
    );
    return response.data;
  }, []);

  const updatePath = useCallback(async (id: number, path: string) => {
    await fetchData(`/dataset/path/${id}`, METHODS.PUT, { path }, false);
  }, []);

  const confirmDataset = useCallback(
    async (id: number, rejected: boolean, transcript: string) => {
      const reload = pathname.includes(
        routes[role.admin].dataset.conflict.paginationPath
      );
      setLoading(true || reload);
      const response = await fetchData(
        `/dataset/confirm/${id}`,
        METHODS.POST,
        {
          rejected,
          transcript,
        },
        false
      );
      if (response.data) {
        getAudioDuration();
        setInfo(`Dataset ${!rejected ? "accepted" : "rejected"}`);
        if (!reload) return;
        getDataset(
          Number(page) - 1,
          sortTypeValue,
          sortOrderValue,
          pathname.split("/")[3] as datasetStatusValueType
        );
      } else setError(response.message);
      setLoading(false);
    },
    [pathname, page, sortOrderValue, sortTypeValue]
  );

  const exportDataset = useCallback(async () => {
    const response = await fetchData(
      "/dataset/export",
      METHODS.GET,
      null,
      false
    );
    if (response.data)
      downloadFile(
        (development ? process.env.REACT_APP_URL : "") + response.data
      );
  }, []);

  return {
    uploadDataset,
    getDataset,
    createDataset,
    deleteDataset,
    editDataset,
    truncateDataset,
    getDatasetById,
    updatePath,
    confirmDataset,
    exportDataset,
  };
};
