import { useEffect } from "react";

export const useScrollHook = (
  wrapper: HTMLDivElement,
  fetchMoreData: () => void
) => {
  useEffect(() => {
    if (!wrapper) return;
    function onScroll(event: Event) {
      const element = event.target as HTMLDivElement;
      if (element.scrollHeight - element.scrollTop === element.clientHeight)
        fetchMoreData();
    }

    wrapper.addEventListener("scroll", onScroll);
    return () => {
      wrapper?.removeEventListener("scroll", onScroll);
    };
  }, [wrapper, fetchMoreData]);
};
