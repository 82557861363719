import {
  DatasetHeader,
  DatasetList,
  datasetStatusValueType,
  useDatasetStore,
} from "@entities";
import { CustomPagination } from "@shared";

type Props = {
  status: datasetStatusValueType;
};

export const DatasetPage = (props: Props) => {
  const { amountOfDatasetItems } = useDatasetStore();
  return (
    <div className="w-full flex flex-col">
      <DatasetHeader />
      <DatasetList status={props.status} />
      <CustomPagination total={amountOfDatasetItems} />
    </div>
  );
};
