import { IWord } from "@entities";
import { METHODS, useAxios } from "@shared";
import { useCallback, useState } from "react";

export const useSearchHook = () => {
  const [words, setWords] = useState<IWord[]>([]);

  const { fetchData } = useAxios();

  const handleSearch = useCallback(
    async (value: string, internal: boolean = true) => {
      if (!value || !Boolean(value.trim())) return setWords([]);
      const response = await fetchData(
        `/words/search?value=${value}`,
        METHODS.GET,
        null,
        false
      );
      if (response.data) {
        if (internal) setWords(response.data);
        return response.data;
      }
      return [];
    },
    []
  );

  return { handleSearch, words };
};
