import { InstructionModalButton, useAuditorStore } from "@entities";
import { Duration, useAppStore } from "@shared";

export const AuditorHeader = () => {
  const { statistics } = useAuditorStore();
  const { user } = useAppStore();
  if (statistics)
    return (
      <div className="w-full flex items-center justify-between pr-4">
        <div className="flex gap-4">
          <div className="flex gap-2">
            <span className="text-neutral-500">Username:</span>
            <span className="italic">{user?.username}</span>
          </div>
          <div className="flex gap-1">
            <span className="text-neutral-500">Accepted: </span>
            <Duration
              seconds={Number(statistics.accepted) || 0}
              format="hh:mm"
            />{" "}
            <span className="text-neutral-400">
              ({statistics.acceptedAmount})
            </span>
          </div>
          <div className="flex gap-1">
            <span className="text-neutral-500">Rejected</span>
            <Duration
              seconds={Number(statistics.rejected) || 0}
              format="hh:mm"
            />
            <span className="text-neutral-400">
              ({statistics.rejectedAmount})
            </span>
          </div>
          <div className="text-warning-500 px-8">
            Warnings: {statistics.resetAmount}
          </div>
        </div>
        <InstructionModalButton />
      </div>
    );
};
