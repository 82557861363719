import { Button, Checkbox, Input } from "@nextui-org/react";
import { orderValues } from "@shared";
import { HiOutlineArrowLongUp, HiOutlineArrowLongDown } from "react-icons/hi2";
import { IoAdd } from "react-icons/io5";
import { useWordsStore } from "../store";
import { BsFiletypeJson } from "react-icons/bs";
import { CgDanger } from "react-icons/cg";
import { FaBroomBall } from "react-icons/fa6";
import { useWordsHook } from "../hook";
import { modalMode, sortValues } from "../types";
import { useSearchParamsHook } from "../hook/params.hook";
import { SearchInput } from "5-entities/searchCorrectWords";
export const WordsHeader = () => {
  const { words, selectedIds, setSelectedIds, setWordsModal } = useWordsStore();
  const { uploadJson, loading } = useWordsHook();
  const handleSelect = (value: boolean) => {
    const data = value ? words.map((word) => word.id) : [];
    setSelectedIds(data);
  };

  const { orderBy, orderDirection, handleChangeSort } = useSearchParamsHook();

  const handleOpenModal = (mode: number) => () => setWordsModal(mode);
  return (
    <div className="flex justify-between w-full px-6 py-2 pt-4">
      <div className="flex gap-4 items-center">
        <div className="relative">
          <Checkbox
            color="default"
            isSelected={
              selectedIds.length && selectedIds.length === words.length
            }
            onValueChange={handleSelect}
          >
            Select all
          </Checkbox>
        </div>
        <Button
          onClick={handleOpenModal(modalMode.delete)}
          isDisabled={!selectedIds.length}
          variant="solid"
          className="bg-red-600"
          size="sm"
        >
          <CgDanger className="text-xl" />
          <span>DELETE</span>
        </Button>
      </div>

      <SearchInput />
      <div className="flex gap-4 items-center">
        <div className="relative">
          <Input
            onChange={uploadJson}
            id="file"
            type="file"
            className="absolute hidden"
            accept=".json,application/json"
          />
          <Button isLoading={loading} className="px-1" size="sm">
            <label
              className="cursor-pointer gap-2 px-2 h-full flex items-center"
              htmlFor="file"
            >
              {!loading && <BsFiletypeJson className="text-xl" />}
              <span>IMPORT WORDS</span>
            </label>
          </Button>
        </div>

        <Button
          onClick={handleOpenModal(modalMode.create)}
          className="flex gap-2"
          size="sm"
          isDisabled={loading}
        >
          <IoAdd className="text-xl" />
          <span>CREATE</span>
        </Button>
        <Button
          size="sm"
          onClick={handleChangeSort(sortValues.sordById)}
          className="flex gap-2"
          color={orderBy === sortValues.sordById ? "success" : "default"}
        >
          {orderDirection === orderValues.ASC ? (
            <HiOutlineArrowLongUp className="text-md" />
          ) : (
            <HiOutlineArrowLongDown className="text-md" />
          )}
          <span>SORT BY ID</span>
        </Button>
        <Button
          size="sm"
          onClick={handleChangeSort(sortValues.sortByWord)}
          color={orderBy === sortValues.sortByWord ? "success" : "default"}
          className="flex gap-2"
        >
          {orderDirection === orderValues.ASC ? (
            <HiOutlineArrowLongUp className="text-md" />
          ) : (
            <HiOutlineArrowLongDown className="text-md" />
          )}
          <span>SORT BY VALUE</span>
        </Button>
        <Button
          onClick={handleOpenModal(modalMode.clear)}
          variant="solid"
          className="bg-red-600"
          size="sm"
        >
          <FaBroomBall className="text-xl" />
          <span>CLEAR</span>
        </Button>
      </div>
    </div>
  );
};
