import {
  METHODS,
  orderValues,
  role,
  routes,
  useAppStore,
  useAxios,
} from "@shared";
import { useCallback, useState } from "react";
import { IWord, limit, searchKeys, sortValues } from "../types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useWordsStore } from "../store";
import { useSearchParamsHook } from "./params.hook";
import { useSearchHook } from "5-entities/searchCorrectWords/hook";

export const useWordsHook = () => {
  const { fetchData } = useAxios();

  const { setWords, updateExistedWord } = useWordsStore();

  const { handleSearch } = useSearchHook();

  const { orderBy, orderDirection, page, searchParams, setSearchParams } =
    useSearchParamsHook();

  const { setInfo, setError } = useAppStore();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const refreshPage = useCallback(
    (keepOrder?: boolean) => {
      const newOrderDirection = keepOrder ? orderDirection : orderValues.DESC;
      const newOrderBy = keepOrder ? orderBy : sortValues.sordById;
      searchParams.set(searchKeys.orderDirection, newOrderDirection);
      setSearchParams(searchParams);
      searchParams.set(searchKeys.orderBy, newOrderBy);
      if (
        Number(page) === 1 &&
        (keepOrder || orderDirection === orderValues.DESC)
      ) {
        getWords(1, newOrderDirection, newOrderBy);
      } else {
        navigate(routes[role.admin].words.path + "?" + searchParams.toString());
      }
      setLoading(false);
    },
    [page, orderDirection, orderBy]
  );

  const uploadJson = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const file = event.target.files[0];
      const formdata = new FormData();

      formdata.append("file", file);
      const response = await fetchData(
        "/words/bulk",
        METHODS.POST,
        formdata,
        false
      );
      event.target.value = "";
      if (!response.data) return setError(response.message);

      setInfo("Words uploaded successfully");
      refreshPage();
    },
    [refreshPage]
  );

  const createWord = useCallback(async (value: string) => {
    setLoading(true);
    const response = await fetchData(
      "/words",
      METHODS.POST,
      { body: value },
      false
    );
    if (!response.data) return setError(response.message);

    setInfo("Word added successfully");
    refreshPage();
  }, []);

  const updateWord = useCallback(async (id: number, value: string) => {
    const response = await fetchData(
      `/words/${id}`,
      METHODS.PUT,
      { body: value },
      false
    );
    if (!response.data) return setError(response.message);
    updateExistedWord({ id, body: value });
    setInfo("Word added successfully");
  }, []);

  const getWords = useCallback(
    async (
      page: number,
      order: string,
      orderBy: string,
      searchQuery?: string
    ) => {
      setLoading(true);
      if (searchQuery && Boolean(searchQuery.trim())) {
        const words = await handleSearch(searchQuery);
        setWords(words, 1);
      } else {
        const response = await fetchData<{ words: IWord[]; count: number }>(
          "/words/getWords",
          METHODS.POST,
          {
            page: page - 1,
            order,
            limit,
            orderBy,
          },
          false
        );
        if (response.data) setWords(response.data.words, response.data.count);
      }

      setLoading(false);
    },
    []
  );

  const truncateWords = useCallback(async () => {
    await fetchData("/words/clear", METHODS.DELETE, {}, false);
    refreshPage(true);
  }, [refreshPage]);

  const deleteSelectedWords = useCallback(
    async (ids: number[]) => {
      await fetchData("/words", METHODS.DELETE, ids, false);
      refreshPage(true);
    },

    [refreshPage]
  );

  return {
    uploadJson,
    getWords,
    loading,
    truncateWords,
    deleteSelectedWords,
    createWord,
    updateWord,
  };
};
