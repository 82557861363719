import { localStorageKey } from "6-shared/constants";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export function downloadFile(url: string): void {
  const anchor: HTMLAnchorElement = document.createElement("a");
  anchor.style.display = "none";
  anchor.target = "_blank";
  document.body.appendChild(anchor);
  anchor.href = url;
  anchor.setAttribute("download", "");
  anchor.click();
  document.body.removeChild(anchor);
}

export function hasNonConsecutiveAsterisks(str: string) {
  let asteriskCount = 0;
  let lastAsteriskIndex = -1;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "*") {
      asteriskCount++;

      if (lastAsteriskIndex !== -1 && i - lastAsteriskIndex > 1) {
        return true;
      }

      lastAsteriskIndex = i;
    }
  }

  return false;
}

export function asteriskisWrongPosition(str: string) {
  return (
    str.replace("'", "").indexOf("*") !== -1 &&
    str.replace("'", "").indexOf("*") !== 1
  );
}

export const exportToExcel = (data: any[]) => () => {
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Set custom column widths
  const columnWidths = [
    { wpx: 100 }, // Width in pixels for the first column
    { wpx: 150 }, // Width in pixels for the second column
    { wpx: 50 }, // Width in pixels for the third column
    { wpx: 50 }, // Width in pixels for the third column
    { wpx: 50 }, // Width in pixels for the third column
    { wpx: 50 }, // Width in pixels for the third column
    // Add more as needed, one object per column
  ];

  worksheet["!cols"] = columnWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `statistics.xlsx`);
};

export function getInfoFromLocalStorage() {
  try {
    const data = localStorage.getItem(localStorageKey);
    if (!data) return "";

    const [id, map] = data.split("-");
    return `?id=${id}&map=${map}`;
  } catch (error) {
    return "";
  }
}

export function checkForDataInLocalStorage(key: string) {
  const defaultData = 0;
  try {
    const data = localStorage.getItem(key);
    if (!data) return defaultData;
    const object = JSON.parse(data);
    if (object.id) return object.id;
    return defaultData;
  } catch (error) {
    return defaultData;
  }
}

export const formatTime = (totalSeconds: number, format: string): string => {
  // Truncate to whole seconds
  const roundedSeconds = Math.floor(totalSeconds);
  const hours = Math.floor(roundedSeconds / 3600);
  const minutes = Math.floor((roundedSeconds % 3600) / 60);
  const secs = roundedSeconds % 60;

  // If a custom format is provided
  if (format) {
    return format
      .replace("hh", String(hours).padStart(2, "0"))
      .replace("mm", String(minutes).padStart(2, "0"))
      .replace("ss", String(secs).padStart(2, "0"));
  }

  // Default format: hh:mm
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const formatPhoneNumber = (value: string, fn: (arg: string) => void) => {
  const digits = value.replace(/\D/g, ""); // Remove non-numeric characters
  let formatted = digits;

  // Apply formatting based on the length of the input
  if (digits.length > 2) {
    formatted = `${digits.slice(0, 2)}-${digits.slice(2)}`;
  }
  if (digits.length > 5) {
    formatted = `${digits.slice(0, 2)}-${digits.slice(2, 5)}-${digits.slice(
      5
    )}`;
  }
  if (digits.length > 7) {
    formatted = `${digits.slice(0, 2)}-${digits.slice(2, 5)}-${digits.slice(
      5,
      7
    )}-${digits.slice(7, 9)}`;
  }
  fn(formatted);
};
