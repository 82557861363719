type Props = {
  text: string;
  className?: string;
};
export const Transcription = (props: Props) => {
  return (
    <div
      className={`h-[250px] text-xl p-4 bg-zinc-900 w-full rounded-lg overflow-y-auto ${props.className}`}
    >
      {props.text}
    </div>
  );
};
