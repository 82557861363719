import { useAppStore } from "@shared";
import { headers } from "./model";
import { Button } from "@nextui-org/react";
import { useAuthHook } from "@entities";
import { InstructionsModal } from "@features";
import { IoLogOutOutline } from "react-icons/io5";
export const Header = () => {
  const { user } = useAppStore();
  const { logout } = useAuthHook();

  return (
    <div className="flex h-[64px] w-full bg-zinc-900 items-center px-6">
      {headers[user.role]}
      <Button className="rounded-full" onClick={logout} isIconOnly size="sm">
        <IoLogOutOutline className="text-xl" />
      </Button>
      <InstructionsModal />
    </div>
  );
};
