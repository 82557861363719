import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import { instructionDelete } from "../types";
import { useInstructionsHook } from "../hooks";

export const InstructionDelete = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const onClose = () => {
    navigate(pathname);
  };
  const isOpen = hash.includes(instructionDelete);
  const { removeInstructions } = useInstructionsHook();
  const handleRemove = () => {
    try {
      removeInstructions(Number(hash.split("_")[1]));
    } catch (error) {}
    onClose();
  };
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      className="dark"
      isDismissable={false}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              Are you sure?
            </ModalHeader>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={handleRemove}>
                Yes
              </Button>
              <Button color="default" onPress={onClose}>
                No
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
