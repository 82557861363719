import { create } from "zustand";
import { ISettingsStore } from "../types";

export const useSettingsStore = create<ISettingsStore>((set, get) => ({
  settings: [],
  setSettings(settings) {
    set({ settings });
  },
  updateSetting(key, value, error = false) {
    set((state) => ({
      settings: state.settings.map((setting) =>
        setting.key === key ? { ...setting, value, error } : setting
      ),
    }));
  },

  getAuditorCheckingLimit() {
    const { settings } = get();
    return Number(
      settings.find((setting) => setting.key === "Auditor checking limit")
        ?.value
    );
  },
  getAuditorTestLimit() {
    const { settings } = get();
    return Number(
      settings.find((setting) => setting.key === "Auditor test limit")?.value
    );
  },

  getWriterCheckingLimit() {
    const { settings } = get();
    return Number(
      settings.find((setting) => setting.key === "Writer checking limit")?.value
    );
  },
  getWriterTestLimit() {
    const { settings } = get();
    return Number(
      settings.find((setting) => setting.key === "Writer test limit")?.value
    );
  },
}));
