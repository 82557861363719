/* eslint-disable react-hooks/exhaustive-deps */
import { METHODS, useAppStore, useAxios } from "@shared";
import { useCallback } from "react";
import { IInstruction } from "../types";
import { useInstructionsStore } from "../model";

export const useInstructionsHook = () => {
  const { fetchData } = useAxios();
  const { setInstructions } = useInstructionsStore();
  const { setInfo, setError } = useAppStore();

  const getInstructions = useCallback(async () => {
    const response = await fetchData<IInstruction[]>(
      "/instructions",
      METHODS.GET,
      null,
      false
    );
    if (response.data) setInstructions(response.data);
  }, []);

  const addInstructions = useCallback(
    async (data: Omit<IInstruction, "id">) => {
      const response = await fetchData<IInstruction>(
        "/instructions",
        METHODS.POST,
        data,
        false
      );
      if (response.data) {
        getInstructions();
        return setInfo("Instruction created successfully");
      }
      setError(response.message);
    },
    []
  );

  const updateInstructions = useCallback(async (data: IInstruction) => {
    const response = await fetchData<IInstruction>(
      "/instructions/" + data.id,
      METHODS.PUT,
      data,
      false
    );
    if (response.data) {
      getInstructions();
      return setInfo("Instruction updated successfully");
    }
    setError(response.message);
  }, []);

  const removeInstructions = useCallback(async (id: number) => {
    const response = await fetchData<IInstruction>(
      "/instructions/" + id,
      METHODS.DELETE,
      null,
      false
    );
    if (response.data) {
      getInstructions();
      return setInfo("Instruction deleted successfully");
    }
    setError(response.message);
  }, []);

  return {
    getInstructions,
    updateInstructions,
    removeInstructions,
    addInstructions,
  };
};
