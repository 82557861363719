export interface IInstruction {
  id: number;
  title: string;
  description: string;
}

export interface IInstructionStore {
  instructions: IInstruction[];
  setInstructions(instructions: IInstruction[]): void;
}

export const instructionDelete = "#instructionDelete_";
