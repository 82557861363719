import { Input } from "@nextui-org/react";
import { openKey, searchKey } from "@shared";
import { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useSearchParams } from "react-router-dom";

export const SearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.get(openKey);

  const time = useRef<NodeJS.Timeout>();

  const searchValue = searchParams.get(searchKey);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (searchValue) setValue(searchValue);
  }, [searchValue]);

  const handleValue = (text: string) => {
    if (time.current) clearInterval(time.current);

    setValue(text);

    const open = isOpen ? { [openKey]: "1" } : {};

    if (!Boolean(text.trim())) return setSearchParams(open);
    time.current = setTimeout(() => {
      setSearchParams({
        [searchKey]: text.trim(),
        ...open,
      });
    }, 400);
  };

  return (
    <div className="flex-1 px-4">
      <Input
        size="sm"
        value={value}
        onValueChange={handleValue}
        placeholder="Search..."
        startContent={
          <CiSearch className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
        }
      />
    </div>
  );
};
